@import "./colors.scss";
:root {
  --primary-color: #000485;

  // Facelift scroll style - START
  &::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent !important; /* color of the tracking area */
    border-radius: 50px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1dde5 !important; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: none;
    height: 10px !important;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 0px;
  }
  // Facelift scroll style - END
}

body {
  word-break: break-word;
  width: 100vw !important;
  overflow-x: hidden;
}
header {
  width: 100vw !important;
}
main {
  width: 100vw !important;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.t-0 {
  top: 0;
}
.r-0 {
  right: 0;
}
.interstride-app-body__baseSubnavLayout {
  padding-top: 114px;
  .interstride-student-main-content-wrapper {
    margin-top: 30px !important;
  }
  @media (max-width: 991px) {
    padding-top: 144px;
  }
  @media (max-width: 767px) {
    padding-top: 130px;
  }
}
.interstride-app-body__withHeadingLayout {
  padding-top: 70px;
  @media (max-width: 991px) {
    padding-top: 106px;
  }
  @media (max-width: 767px) {
    padding-top: 96px;
  }
  header {
    position: initial;
    .header-wrapper {
      position: fixed;
      width: 100vw;
      top: 0;
      z-index: 12;
    }
  }
}

.interstride-app-body__withHeadingLayout-profile {
  padding-top: 96px;
  @media (min-width: 635px) {
    padding-top: 106px;
  }
  @media (min-width: 991px) {
    padding-top: 71px;
  }
  header {
    position: initial;
    .header-wrapper {
      position: fixed;
      width: 100vw;
      top: 0;
      z-index: 12;
    }
  }
}

.interstride-app-body__authlayout {
  padding-top: 0px;
}

.text-primary {
  color: var(--primary-dark-color);
}
.text-white {
  color: #fff;
}
.text-gray {
  color: var(--gray-text-color);
}
.text-secondary {
  color: var(--secondary-color) !important;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 19px;
  font-family: "TTCommons-Regular";
  text-decoration: none;
}
.text-center {
  text-align: center !important;
}
.bg-primary {
  background: var(--primary-color);
}
.bg-secondary {
  background: var(--secondary-color) !important;
}
.bg-primary-main {
  background: var(--primary-main-color) !important;
}
.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-none {
  flex: none;
}
.flex-flow-column {
  flex-flow: column;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: end !important;
}
.justify-left {
  justify-content: left !important;
}
.abs-right {
  position: absolute;
  right: 0;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mr-6 {
  margin-right: 6px;
}
.m-1 {
  margin: 0;
}
.m-2 {
  margin: 10px;
}
.m-3 {
  margin: 20px;
}
.w-70 {
  width: 70px !important;
}
.font-25 {
  font-size: 25px !important;
}
.font-bold {
  font-weight: 700;
}
.w-100 {
  width: 100% !important;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-8 {
  margin-left: 8px;
}
.pl-8 {
  padding-left: 8px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pt-3 {
  padding-top: 3px;
}

.mb-15 {
  margin-bottom: 15px;
}
.mb-23 {
  margin-bottom: 23px;
}
.overflow-initial {
  overflow: initial;
}
.pos-relative {
  position: relative;
}
.border-0 {
  border: 0 !important;
}
.text-disable {
  opacity: 0.5;
}
.title-primary {
  color: var(--primary-dark-color);
  font-family: "TTCommons-Medium";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 4px;
  word-break: break-word;
  margin: 0;
}
.header-primary {
  color: var(--primary-dark-color);
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  word-break: break-word;
}
.sub-header-primary {
  color: var(--primary-dark-color);
  font-family: "TTCommons-Regular";
  font-size: 15px;
  line-height: 19px;
  display: block;
  width: 100%;
  word-break: break-word;
}
.text-grey {
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  color: var(--f-light-gray-color);
  font-weight: 400;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: auto;
}
.cursor-notallowed {
  cursor: not-allowed;
}
.pointer-none {
  pointer-events: none !important;
}
.discover-topic-info-panel {
  cursor: initial !important;
  overflow: visible;
}
.MuiDialog-paperWidthMd {
  border-radius: 10px;
}
.form-label {
  font-family: "TTCommons-Regular";
  font-size: 15px;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  letter-spacing: 0;
  line-height: 17px;
  justify-content: left;
  padding: 0;
  margin: 0 0 6px 8px;
  width: 100%;
  color: var(--primary-color);
}
.form-control {
  width: 100% !important;
}
.MuiInputBase-formControl fieldset {
  border: 0 !important;
}
.z-index .react-custom-dropdown {
  z-index: 10;
}
.react-custom-dropdown > div {
  padding: 4px 12px 0;
}
.react-custom-dropdown .css-15ev9ge-control {
  border-radius: 45px;
}
.react-custom-dropdown > div {
  border: 2px solid var(--gray-outline-color);
  background-color: #ffffff !important;
  width: 100%;
  box-shadow: none;
  &:hover {
    border-color: #bfced8;
  }
  &:focus {
    border-color: var(--gray-color);
  }
}

.react-custom-dropdown {
  > span {
    + div {
      + div {
        > div {
          > div {
            &:active {
              background-color: var(--primary-dark-color);
            }
          }
        }
      }
    }
  }
  > div {
    &:nth-child(3) {
      > div {
        scrollbar-width: thin;
        -ms-overflow-style: auto;
        overscroll-behavior: none;
        scrollbar-width: thin; /* "auto" or "thin" */
        scrollbar-color: $color-grey transparent; /* scroll thumb and track */
        transition: scrollbar-color 0.3s ease-out;
        &::-webkit-scrollbar {
          width: 5px; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: transparent !important; /* color of the tracking area */
          border-radius: 50px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-grey !important; /* color of the scroll thumb */
          border-radius: 20px; /* roundness of the scroll thumb */
          border: none;
          height: 10px !important;
        }

        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }

        &::-webkit-scrollbar-thumb:hover {
          border: 0px;
        }
      }
    }
  }
}
.react-custom-dropdown__valid > div:first-child {
  border-color: var(--gray-color) !important;
}
.react-custom-dropdown.showValueBorder > div {
  border: none;
}
.react-custom-dropdown.multi-select > div:last-child {
  border: 0 !important;
}
.react-custom-dropdown .css-15ev9ge-control .css-ri91xb-multiValue {
  margin: 0;
  border-radius: 10.5px;
  background-color: #e5eaf7;
  padding: 2px 8px 1px 9px;
  + .css-ri91xb-multiValue {
    margin-left: 5px;
  }
}
.react-custom-dropdown .css-15ev9ge-control .css-ri91xb-multiValue .css-kbftzx {
  padding: 0;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  color: var(--primary-color);
}
.react-custom-dropdown .css-15ev9ge-control .css-ri91xb-multiValue .css-xb97g8 {
  padding: 0 0 0 5px;
  margin: -2px 0 0;
}
.profile-react-custom-dropdown.border-gray > div:first-child {
  border-color: var(--gray-color) !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-25 {
  margin-top: 25px;
}
.mt-3 {
  margin-top: 3px !important;
}
.mt6 {
  margin-top: 6px;
}
.mt-10 {
  margin-top: 10px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-30 {
  margin-top: 30px !important;
}
.mb-18 {
  margin-bottom: 18px;
}
.mb-17 {
  margin-bottom: 17px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
*:focus {
  outline: 0;
}
.custom-checkbox .MuiFormControlLabel-label {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  font-family: "TTCommons-Regular";
  display: block;
  padding-top: 2px;
  color: var(--gray-text-color);
}
.custom-checkbox .round-ckeckbox {
  padding: 0;
  height: 16px;
  width: 16px;
  border: 0;
  background-color: #f1f4f7;
  border-radius: 100px;
  outline: 0;
  box-shadow: none;
  margin: 0 10px 0 0;
  transition: none;
}
.custom-checkbox .login-checkbox {
  width: 19px;
}
.custom-checkbox .add-border {
  border: 1px solid #000485;
}
.custom-checkbox .round-ckeckbox svg {
  display: none;
}
.custom-checkbox .round-ckeckbox.Mui-checked {
  background: #000c3d;
  border-color: #000c3d;
  transition: none;
}
.custom-checkbox .round-ckeckbox.Mui-checked:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
}

.MuiGrid-spacing-xs-3 {
  margin: -8px;
  width: calc(100% + 16px);
  > .MuiGrid-item {
    padding: 8px;
  }
}
.sort-by {
  display: flex;
  align-items: center;
  white-space: nowrap;
  .sortby {
    color: var(--gray-text-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
    margin: 0 10px 0 0;
  }
  > div {
    width: 100%;
    margin: 0;
  }
  .react-custom-dropdown {
    min-width: 150px;
    > div:first-child,
    > span + div {
      height: 35px;
      min-height: 35px;
      padding: 2px 12px 0 10px;
      background-color: #ffffff;
      box-shadow: var(--card-box-shadow) !important;
      border: 0;
      * {
        color: var(--primary-dark-color);
      }
    }
    > span + div + div,
    > div + div {
      padding: 0;
      border-radius: 8px;
      box-shadow: var(--card-box-shadow) !important;
      border: 0;
    }
    img[alt="Dropdown arrow"] {
      margin-top: -3px;
    }
  }
}

/** custome dropdown */
.react-custom-dropdown {
  border-color: 2px solid var(--gray-outline-color);
  border-radius: 45px;
  box-sizing: 0;
  &.showValueBorder {
    border: 2px solid var(--gray-text-color);
  }
  .css-1wrpugs-control {
    &:hover {
      border-color: var(--gray-outline-color);
    }
  }
  .css-isfiuo-placeholder {
    padding-top: 3px;
    box-sizing: border-box;
  }
}
.custom-textbox {
  input,
  select {
    border: 2px solid var(--gray-outline-color);
    border-radius: 45px;
    background-color: #ffffff;
    padding: 16.65px 20px 12.65px 20px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19px; // color: var(--gray-text-color);
    color: var(--primary-dark-color) !important;
    font-family: "TTCommons-Regular" !important;
    box-sizing: border-box;
    min-height: 50px;
    margin: 0;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &:hover {
      border-color: #bfced8;
    }
  }
}
.a-input-profile__completed input {
  border: 2px solid var(--gray-color);
  border-radius: 45px;
}
.onboarding-wrapper {
  button:not(.MuiIconButton-root) {
    margin: 30px auto 0;
    @media (max-width: 767px) {
      margin: 24px auto 0;
    }
  }
  button.linkedIn-btn {
    margin: 0 auto;
  }
  .login-layout,
  .reset-password {
    max-width: 326px;
    margin: 0 auto;
    .display-error {
      padding: 0 !important;
      position: relative;
      margin: 15px 0 5px;
      display: block;
      font-size: 15px;
      line-height: 17px;
      .text-secondary {
        text-decoration: none;
        border-bottom: 1px solid var(--secondary-color);
      }
    }
  }
  form {
    max-width: 325px;
    margin: 0 auto;
    .display-error {
      padding: 0 !important;
      position: relative;
      margin: 10px 0 5px;
      font-size: 15px;
      line-height: 17px;
      .text-secondary {
        text-decoration: none;
        border-bottom: 1px solid var(--secondary-color);
      }
    }
    .disabledInput {
      input.Mui-disabled {
        background-color: var(--icon_background);
        border-color: var(--icon_background);
      }
    }
  }
  .field-group {
    width: 100%;
    .textbox-label {
      margin: 0 0 7px 0 !important;
      padding: 5px 0 0 0 !important;
      line-height: 12px !important;
      color: var(--field-label-color) !important;
      display: flex;
      sup {
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
    .graduation-label {
      display: inline;
    }
    .custom-textbox {
      input {
        text-align: center;
      }
    }
  }
  .mandatory-fields-description-text {
    margin: 50px 0 0 0;
    color: #8094ab;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    @media (max-width: 767px) {
      margin: 24px 0 0 0;
    }
  }
  .forgot-passwordlink {
    margin-top: 50px;
    text-decoration: none;
    border-bottom: 1px solid rgb(96, 119, 144);
    &:focus {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }
}
.Mui-error input,
.Mui-error select,
.Mui-error textarea {
  border-color: var(--secondary-color) !important;
  opacity: 1;
}
.custom-textarea {
  > div {
    margin: 0;
    padding: 0;
  }

  textarea {
    border: 2px solid var(--gray-outline-color);
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px;
    color: var(--primary-dark-color) !important;
    font-family: "TTCommons-Regular" !important;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: LEFT;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--field-label-color) !important;
      opacity: 1 !important;
    }
    &:hover {
      border-color: var(--medium-gray-color);
    }
    // --Focus style
    &:focus {
      border-color: var(--gray-color);
    }
  }
  &.showValueBorder {
    textarea {
      border-color: var(--gray-text-color);
    }
  }
}
// --Focus style
// textarea {
//   &:focus {
//     box-shadow: 0 0 0px 2px var(--primary-color), inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
//   }
// }
/** React tag Input **/
/** Default Model Backgroud **/
.MuiBackdrop-root {
  background-color: rgba(128, 148, 171, 0.4);
  overflow: hidden;
}
.toast-bottom {
  position: fixed;
  bottom: 30px;
  width: 100%;
  max-width: 709px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: #0030b5;
  color: #ffffff;
  .toast {
    padding: 20px 40px 17px 30px;
    display: flex;
    justify-content: space-between;
    .msg {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
    }
    .action {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      border-bottom: 1px solid #ffffff;
      cursor: pointer;
    }
  }
}
:focus {
  outline: 0;
}
*,
::after,
::before {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  padding-top: 58px;
  @media (max-width: 991px) {
    padding-top: 88px;
  }
  @media (max-width: 767px) {
    padding-top: 88px;
  }
}
header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}
.loader-main {
  text-align: center;
  & > div {
    display: inline-block;
  }
}
.dialogue-back-btn {
  height: 21px !important;
  width: 21px !important;
  margin: -5px 0 0 0 !important;
}
.dialogue-back-btn + span {
  margin: 0;
  color: var(--primary-color);
  font-family: "TTCommons-Medium";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.MuiOutlinedInput-input {
  &:focus {
    &::placeholder {
      color: transparent !important;
    }
  }
}
input {
  &:focus {
    &::placeholder {
      color: transparent !important;
    }
  }
}
.Toastify {
  .Toastify__toast-container--bottom-center {
    width: 100% !important;
    max-width: 650px !important;
    min-width: 650px !important;
    padding: 0;
    .Toastify__toast {
      box-shadow: none;
      border: 0;
      min-height: auto;
      border-radius: 10px;
      background-color: var(--primary-main-color);
      margin: 0;
      padding: 20px 30px 17px 24px;
      transform: none !important;
      + .Toastify__toast {
        margin-top: 10px;
      }
      .Toastify__toast-body {
        color: #ffffff;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        margin: 0;
        padding: 3px 0 0;
        svg {
          display: none;
        }
      }
      button {
        box-shadow: none !important;
        opacity: 1;
        :focus {
          svg {
            box-shadow: 0 0 0px 2px var(--gray-outline-color) !important;
            border-radius: 45px;
          }
        }
        svg {
          width: 16px;
          height: 16px;
          right: -10px;
          position: relative;
        }
      }
    }
    .Toastify__progress-bar {
      &--success,
      &--error {
        visibility: hidden;
      }
      visibility: hidden;
    }
  }
}
.checklist-dnd {
  > li {
    margin-bottom: 15px;
    list-style: none;
    .checklist-card {
      .card-content {
        padding: 17px 39px 17px 20px;
        .checklist-task {
          display: flex;
          align-items: flex-start;

          .checklist-control {
            display: flex;
            align-items: flex-start;
            width: 100%;

            .MuiRadio-root {
              padding: 0;
              margin: 0 15px;
            }

            .MuiIconButton-colorPrimary {
              &:hover {
                background-color: transparent;
              }
            }
            .MuiIconButton-root {
              &:hover {
                background-color: transparent;
              }
            }

            & span.Mui-checked + div {
              text-decoration: line-through;
              text-decoration-color: var(--secondary-color);
            }
            &.task-control {
              .btn-edit {
                opacity: 0;
              }
              .btn-close {
                opacity: 0;
              }
              &:hover {
                .btn-edit {
                  opacity: 1;
                }
                .btn-close {
                  opacity: 1;
                }
              }
            }

            .title {
              font-size: 22px;
              font-family: "TTCommons-Medium";
              width: 75%;
              padding: 0;
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              input[type="text"] {
                font-family: "TTCommons-Medium";
                border: 0;
                font-size: 22px;
                letter-spacing: 0;

                color: var(--gray-text-color);
                outline: 0 !important;
                padding: 0;
                width: 100%;
              }
              > div {
                &:first-child {
                  max-width: 100%;
                  width: 100%;
                }
                &:last-child {
                  max-width: 100%;
                  width: 100%;
                }
              }
              .link-text {
                display: inline-block;
                margin-left: 20px;
                color: var(--secondary-color);
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 19px;
                text-decoration: underline;
                margin-left: 0;
              }
              &.completed {
                > div.primaryTask {
                  text-decoration: line-through;
                }
              }
            }

            .subtitle {
              font-size: 18px;
              font-family: "TTCommons-Regular";
              width: 90%;
              color: var(--primary-color);
              input[type="text"] {
                font-family: "TTCommons-Regular";
                border: 0;
                font-size: 18px;
                letter-spacing: 0;
                color: var(--gray-text-color);
                outline: 0 !important;
                padding: 0;
                width: 100%;
              }

              &.completed {
                text-decoration: line-through;
              }
            }
            .btn-edit {
              font-size: 18px;
              font-family: "TTCommons-Medium";
              text-decoration: underline;
              margin-right: 50px;
              color: var(--secondary-color);
              cursor: pointer;
              min-width: auto;
              white-space: nowrap;
            }
            .btn-close {
              border: 0;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              background: #e5eaf7;
              display: flex;
              justify-content: center;
              align-items: center;
              outline: none;
              cursor: pointer;
              padding: 0;
              flex: 0 0 30px;
              min-width: auto;
              img {
                width: 10px;
              }
            }
          }
          button {
            padding: 2px 0 0;
            min-width: 134px;
          }
        }
        .sub-tasks {
          list-style: none;
          padding: 0;
          margin-left: 60px;
          margin-top: 15px;

          li {
            margin-bottom: 25px;
            &:last-child {
              margin-bottom: 0;
            }
            .checklist-control {
              align-items: center;
              .ncheckbox-wrapper {
                margin: -4px 0 0;
              }
            }
          }
        }
      }
    }
  }
}

.relative-div {
  position: relative;
  .breadcrumb-div {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    ul {
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      display: flex;
      align-items: center;
      list-style: none;
      padding: 11px 20px;
      margin: 0;
      li {
        a {
          color: var(--gray-text-color);
          font-family: "TTCommons-Regular";
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
          text-decoration: none;
          border-bottom: 1px solid transparent;
        }
        span {
          color: var(--secondary-color);
          font-family: "TTCommons-Regular";
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
          border-bottom: 1px solid var(--secondary-color);
        }
        + li {
          padding-left: 30px;
          position: relative;
          &:before {
            content: " >> ";
            position: absolute;
            left: 10px;
            color: var(--gray-text-color);
            font-family: "TTCommons-Regular";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.profile-items:nth-child(2) .profile-upload .profile-img-wrap {
  border-radius: 0;
  height: auto;
}
.profile-items:nth-child(2) .profile-upload .profile-img-wrap img {
  border-radius: 0 !important;
}
.feedback-dialog .issueType {
  max-width: 285px;
  margin: 0 auto;
  .react-custom-dropdown {
    text-align: center;
  }
}
.chat-group-dialog,
.create-topic-dialog,
.new-msg-dialog {
  .MuiDialogContent-root {
    overflow: hidden !important;
    @media (max-width: 1024px) {
      overflow-y: auto !important;
    }
  }
}

.text-warning {
  margin: 0 0 25px;
  color: #ff5f59;
  font-family: "TTCommons-Regular";
  font-size: 16px;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  letter-spacing: 0;
  line-height: 16px;
}
.crop-btn {
  max-width: 248px !important;
  margin: 30px 0 0 0 !important;
  width: 100% !important;
  height: 60px !important;
  border-radius: 45px !important;
}
.remove-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .btn-upload {
    color: var(--link-color);
    font-family: "TTCommons-Medium";
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    border-bottom: 2px solid var(--link-color);
    padding-top: 2px;
    text-decoration: none;
    span {
      border: 0 !important;
    }
  }
}
.removeicon-center {
  margin: 0 4px !important;
  + .removeicon-center {
    margin: 0px 0 0 0 !important;
  }
}
.company-list {
  .MuiGrid-spacing-xs-3 {
    margin: 0;
    width: 100%;
    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
    .MuiGrid-grid-xs-12 {
      padding-left: 0;
      padding-right: 0;
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
.company .body-section .card-top .react-custom-dropdown > div {
  min-width: 180px;
}
.cropper-modal {
  background-color: #f8f6f6 !important;
  opacity: 0.5;
}
.cropper-view-box {
  //height: 66% !important;
  //width: 65% !important;
  box-shadow: 0 0 0 2px var(--secondary-color);
}
.clearallBtnWrap {
  margin: 30px 0 0;
  a {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    text-decoration: none;
    border-bottom: 1px solid var(--gray-color);
    font-family: "TTCommons-Regular";
    display: inline-block;
    color: var(--gray-color);
    &:focus {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }
}
.MuiDialog-container {
  .MuiDialogContent-root {
    .closeModal {
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 1;
      button {
        opacity: 1;
        background: transparent;
        -webkit-tap-highlight-color: transparent;
      }
    }
    .warnning-text {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-weight: 400;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin: 10px auto 0;
      max-width: 340px;
    }
  }
}
.modal-cancellink {
  &:hover {
    color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
  }
  &:focus {
    color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
  }
}
.gray-linktext {
  text-decoration: none;
  white-space: nowrap;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  border-bottom: 1px solid var(--gray-text-color);
  color: var(--gray-text-color);
}
.star {
  a {
    display: flex;
    position: relative;
    min-width: 21px;
    img {
      opacity: 0.8;
      &.filled-star {
        opacity: 1;
      }
    }
    &:focus,
    &:hover {
      img {
        opacity: 1;
      }
    }
    // --Focus style
    // &:focus {
    //   &:after {
    //     content: "";
    //     position: absolute;
    //     box-shadow: 0 0 0px 2px var(--primary-color), inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
    //     width: 30px;
    //     height: 30px;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //   }
    // }
  }
}
.nlink-secondary {
  font-size: 18px;
  line-height: 16px;
  color: var(--link-color);
  font-family: "TTCommons-Medium";
  font-weight: 500;
  text-decoration: none;
  border-bottom: 2px solid var(--link-color) !important;
  cursor: pointer;
  padding-top: 3px;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s;
  &:hover {
    opacity: 0.9;
  }
}
.nlink-secondary-dark {
  font-size: 18px;
  line-height: 16px;
  color: var(--primary-dark-color);
  font-family: "TTCommons-Medium";
  font-weight: 500;
  text-decoration: none;
  border-bottom: 2px solid var(--primary-dark-color);
  cursor: pointer;
  padding-top: 3px;
  transition: all 0.3s;
  &:hover {
    opacity: 0.9;
  }
}
.ngray-link {
  font-size: 18px;
  line-height: 16px;
  color: var(--gray-text-color);
  font-family: "TTCommons-Medium";
  font-weight: 500;
  text-decoration: none;
  border-bottom: 2px solid var(--gray-text-color);
  cursor: pointer;
  padding-top: 3px;
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
  }
}
.nshow-link {
  font-size: 18px;
  line-height: 16px;
  color: var(--primary-dark-color);
  font-family: "TTCommons-Medium";
  font-weight: 500;
  text-decoration: none;
  border-bottom: 2px solid var(--primary-dark-color);
  cursor: pointer;
  display: inline-block;
  padding-top: 3px;
  transition: all 0.3s;
  &:hover {
    opacity: 0.9;
  }
}
.link-content {
  color: var(--link-color);
  text-decoration: none;
  cursor: pointer;
  padding-top: 3px;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s;
}
.pages-back-btn {
  background: #fff !important;
  box-shadow: var(--card-box-shadow) !important;
  color: var(--field-label-color);
  width: auto;
  padding: 18px 20px 12px;
  height: auto;
  min-height: 50px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  font-family: "TTCommons-Regular";
  border-radius: 100px;
  text-decoration: none;
  transition: all 0.3s;
  display: inline-block;
  text-transform: initial;
  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
  }
  img {
    margin: -2px 6px 0 0;
    width: 8px;
    vertical-align: middle;
    transform: rotate(180deg);
  }
}
.title-boxs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-end;
  .title-primary {
    margin-right: 12px;
  }
}
.nlink-btn {
  font-family: "TTCommons-Medium";
  font-size: 18px;
  padding: 7px 8px 3px;
  box-shadow: none !important;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;
  text-align: center;
  border-radius: 100px;
  min-width: 180x;
  line-height: normal;
  vertical-align: middle;
  min-height: 32px;
}
.nbg-primary {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  &:hover {
    opacity: 0.9;
  }
}
.nbg-secondary {
  background-color: var(--secondary-color) !important;
  color: #fff !important;
  transition: all 0.3s;
  &:hover {
    opacity: 0.9;
  }
}
.nbg-gray {
  background-color: var(--gray-color) !important;
  color: #fff !important;
  &:hover {
    opacity: 0.9;
  }
}
.nbg-widget {
  background-color: var(--widget-theme-color) !important;
  color: #fff !important;
  transition: all 0.3s;
  &:hover {
    opacity: 0.9;
  }
}
.nbtn-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.datepicker-wrapper {
  position: relative;
  .react-datepicker-popper {
    left: auto !important;
    top: 75px !important;
    transform: none !important;
    width: 100%;
    margin-left: 0;
  }
}
.timepicker-wrapper {
  position: relative;
  .react-datepicker-popper {
    left: 0 !important;
    top: 100px !important;
    transform: none !important;
    margin-left: 0;
    width: 100%;
    .react-datepicker__time-container {
      float: none;
    }
  }
}
.upload-signup-profile-pic {
  max-width: 326px;
  margin: 0 auto 100px;
  p {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }
  .profile-img-wrappper {
    label {
      margin: 0 0 7px 0 !important;
      padding: 5px 0 0 0 !important;
      line-height: 12px !important;
      color: var(--field-label-color) !important;
      display: block;
    }
    .profile-img-wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }
  }
  .profile-upload-link {
    .removeicon-center {
      img {
        width: 18px;
        height: auto;
        margin: 0 10px 0 0;
      }
    }
  }
}
.mb-5 {
  margin-bottom: 5px !important;
}
.notification-loader {
  height: 100%;
  .d-flex {
    height: 100%;
  }
}
.ncheckbox-wrapper {
  margin: 0;
  .MuiRadio-root {
    padding: 0;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    outline: 0;
    box-shadow: none;
    background-color: #f1f4f7;
    &:hover {
      background-color: #f1f4f7 !important;
    }
    .MuiSvgIcon-fontSizeSmall {
      display: none;
    }
    &.Mui-checked {
      border: 0;
      svg {
        display: block;
        width: 24px;
        height: 24px;
      }
    }
  }
}
.note-text {
  display: flex;
  align-items: center;
  font-family: "TTCommons-Regular";
  font-size: 18px;
  color: var(--primary-dark-color);
  line-height: 18px;
  margin: 0 0 8px;
  img {
    width: 18px;
    margin: -5px 8px 0 0;
  }
}

.popover-wrapper {
  background-color: rgba(128, 148, 171, 0.4);
  .MuiPopover-paper {
    max-width: 348px;
    padding: 20px 20px 16px;
    border-radius: 0px 10px 10px 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    margin-left: -8px;
    margin-top: 8px;
    h3 {
      font-family: "TTCommons-Medium";
      font-weight: 500;
      margin: 0 0 5px 0;
      font-size: 18px;
      line-height: 19px;
    }
    p {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: left;
      b {
        font-family: "TTCommons-DemiBold";
        font-weight: 600;
      }
      p {
        margin: 5px 0;
      }
    }
    .popover-link {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      border-bottom: 1px solid var(--primary-dark-color);
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.npopover-wrapper {
  .MuiPopover-paper {
    max-width: 348px;
    border-radius: 10px 1px 10px 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    padding: 20px;
    margin-left: -10px;
    margin-top: 8px;
    h2 {
      margin: 0 auto 4px;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
    }
    p {
      margin: 0 auto 0;
    }
  }
  &.no-top-left-corner-popover {
    .MuiPopover-paper {
      border-radius: 0 10px 10px 10px !important;
    }
  }
}

.title-container {
  text-align: center;
  margin-bottom: 30px;
  .title {
    width: 100%;
    height: 38px;
    width: 221px;
    color: var(--primary-color);
    font-size: 42px;
    font-weight: 600;
  }
}

.notification-switch {
  display: flex;
  justify-content: flex-end;
  margin-top: -62px;
  margin-bottom: 20px;
  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
  .notification-switch-content {
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    border-radius: 100px;
    padding: 18px 20px 13px;
    display: flex;
    align-items: center;
    color: #000c3d;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    + .notification-switch-content {
      margin-left: 10px;
      @media (max-width: 480px) {
        margin-left: 0;
      }
    }
    @media (max-width: 480px) {
      padding: 12px 12px 10px;
      margin: 0 8px;
    }
  }
  .MuiFormGroup-root {
    margin-left: 10px;
    @media (max-width: 480px) {
      margin-left: 0;
    }
  }

  &-top {
    display: flex;
    &-span {
      margin-right: 115px;
    }
  }
  &-bottom {
    margin-top: 7px;
    display: flex;
    &-span {
      margin-right: 25px;
    }
  }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    border-radius: 10px;
    background-color: #fafbfd;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.2);
    margin: 10px 0 0 0px;
    padding: 12px 20px 8px;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    color: #000c3d;
    max-width: 348px;
    a {
      white-space: nowrap;
      text-decoration: none;
      border-bottom: 1px solid var(--link-color);
      font-family: "TTCommons-Medium";
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.calendar-card {
  .fc-view {
    position: initial !important;
  }
  .fc-scroller.fc-scroller-liquid-absolute {
    position: initial !important;
    overflow: hidden !important;
  }
  .fc-scroller {
    overflow: hidden !important;
  }
  td.fc-daygrid-day {
    padding-bottom: 15px !important;
  }
  .fc-view-harness {
    padding: 0 !important;
    height: auto !important;
  }
}
.collapse-icon {
  display: none;
}

// focus css
.link-focus:focus {
  .back-link {
    + span {
      border-bottom: 2px solid #000c3d;
      color: #000c3d;
    }
  }
}

// Outline css starts
body.accessibility .link-focus:focus {
  outline: 1px solid #000c3d;
  outline-offset: 2px;
}
body.accessibility #post-action-accessibility .link-focus:focus {
  outline: none;
}
// Outline css ends

.img-link {
  text-decoration: none !important;
  display: flex !important;
  align-items: center !important;
  img {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  span {
    border-bottom: 1px solid var(--secondary-color);
  }
}

.MuiButton-root {
  transition: all 0.3s;
  &:hover {
    opacity: 0.9;
    transition: all 0.3s;
  }
  // &:focus {
  //   box-shadow: 0 0 8px 5px #000c3d !important;
  // }
}

// .bg-gray {
//   &:focus {
//     background: var(--gray-color-btn-hover) !important;
//     opacity: 1 !important;
//   }
// }

.bg-registered {
  background-color: #8094ab !important;
}

// .bg-secondary {
//   &:focus {
//     background: var(--secondary-color-btn-hover) !important;
//     opacity: 1 !important;
//   }
// }

.bg-light-blue {
  &:focus {
    background: #264fc0 !important;
    opacity: 1 !important;
  }
}

.switch-text {
  color: #000c3d !important;
  border-color: #000c3d !important;
  border-bottom: 1px solid #000c3d;
}

.ncustom-switch {
  .MuiSwitch-root {
    margin: -3px 0 0px 10px;
  }
  > span {
    border-bottom: 1px solid transparent;
    line-height: 18px;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    color: var(--primary-dark-color);
  }
  .Mui-disabled + .MuiSwitch-track {
    background-color: var(--light-gray-color);
    opacity: 1;
  }
  // &.switch-focus {
  //   .MuiSwitch-track {
  //     box-shadow: 0 0 8px 1px #000c3d !important;
  //   }
  //   .MuiSwitch-thumb {
  //     box-shadow: 0 0 4px 1px #000c3d !important;
  //     background-color: #000c3d;
  //   }
  //   .MuiSwitch-root {
  //     border-bottom: 0;
  //   }
  //   > span {
  //     border-bottom: 1px solid #000c3d;
  //     color: #000c3d;
  //   }
  // }
}

.img-focus {
  &:focus {
    + p {
      color: #000c3d !important;
      border-color: #000c3d !important;
    }
  }
}

// .circle-boxshadow {
//   &:focus {
//     box-shadow: 0 0 5px 3px #000c3d !important;
//   }
// }

.alert-icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  border-radius: 100px;
  margin: -4px 0 0 8px;
  img {
    width: 100%;
  }
}
.list-jobs-alert {
  margin: 0 0 0 8px;
}

.attachment-post,
.attatcment {
  .btn-upload {
    border-radius: 100px;
    width: 30px;
    height: 30px;
    justify-content: center;
    margin-top: -1px;
    img {
      margin: 0;
    }
    &:focus {
      color: #000c3d !important;
      box-shadow: 0 0 5px 3px #000c3d !important;
    }
  }
}

.link-focus {
  &:focus {
    .text-secondary {
      color: #000c3d !important;
      border-color: #000c3d !important;
    }
  }
}

.social {
  a {
    &:focus {
      color: #000c3d !important;
      span {
        box-shadow: 0 0 8px 4px #000c3d !important;
        border-radius: 100px;
      }
    }
  }
}

.form-control {
  &:focus {
    box-shadow: 0 0 5px 3px #000c3d !important;
  }
}

.back-link {
  width: 18px;
  margin: -8px 10px 0 0;
  + span {
    border-bottom: 2px solid transparent;
    line-height: 18px;
  }
}
.title-primary {
  a {
    .back-link {
      margin: -4px 10px 0 0;
    }
  }
}
.card-close-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 24px;
  }
  // &:focus {
  //   img {
  //     box-shadow: 0 0 8px 4px #000c3d;
  //   }
  // }
}

.nback-arrow-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--primary-color);
  font-family: "TTCommons-Medium";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;

  .back-link {
    margin: -4px 8px 0 0;
  }
}

.dashboard-post a {
  &:focus {
    img {
      box-shadow: 0 0 8px 4px #000c3d;
    }
  }
}

.edit-saved-search {
  .MuiPaper-root {
    overflow: visible;
  }
}

.focus-dots {
  &:focus {
    color: #000c3d !important;
  }
}

.filter-group {
  .MuiAccordionSummary-root {
    border-radius: 10px;
    &.Mui-focusVisible {
      box-shadow: 0 0 5px 3px #000c3d !important;
      background-color: #fff;
    }
  }
}

.modal-close-icon {
  display: flex;
  align-items: center;
  img {
    width: 28px;
  }
}

.resource-section {
  .f-main-title {
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
}

.accessibility-text {
  display: flex;
  align-items: center;
  .MuiRadio-root {
    margin: -5px 8px 0 0;
    padding: 0;
    .MuiTouchRipple-root {
      display: none;
    }
  }
}
.cCOoAV {
  display: none !important;
}
.preview-url-post {
  text-decoration: none;
}
.preview-nbox-wrapper {
  margin: 15px 0 0;
  .img-wrap {
    height: 275px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h3 {
    margin: 15px 0 15px;
    color: var(--primary-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
  }
  h4 {
    margin: 0;
    color: var(--primary-color);
    font-family: "TTCommons-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }
  h5 {
    margin: 0;
    color: var(--gray-text-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    line-height: 15px;
  }
  .topic-preview-video-section {
    margin: 0 0 30px;
  }
  .title-align {
    margin-top: 10px !important;
  }
}

.petition-header {
  border-radius: 8px;
}

.spinner-div {
  > div {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(0, 4, 133) transparent rgb(0, 4, 133) rgb(0, 4, 133);
    border-image: initial;
    border-radius: 50%;
    animation: 0.75s linear 1s infinite normal none running eXzyYY;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sso-popup {
  padding: 0 !important;
  .sso-popup-content {
    padding: 50px;
    center {
      font-family: "TTCommons-Medium";
      font-weight: 500;
    }
    .university-form {
      margin: 30px 0 0 0 !important;
      padding: 0;
      .image-upload {
        margin: 0 0 50px;
        .dropzone {
          .MuiAvatar-root {
            width: 100px;
            height: 100px;
            margin: 0 auto 15px;
            background: #d1dde5;
          }
        }
        .remove-icon {
          flex-wrap: wrap;
          div {
            width: 100%;
          }
        }
      }
      button {
        margin-bottom: 30px !important;
        font-size: 22px;
        .crop-btn {
          margin: 0 0 30px 0 !important;
        }
      }
      .profile-img-wrap {
        width: 120px !important;
        height: 120px;
        margin: 0 0 15px 0;
        border-radius: 100px;
        overflow: hidden;
        img {
          width: 100%;
          border-radius: 0 !important;
        }
        .initial-avatar {
          width: 100%;
          height: 100%;
          font-size: 65px;
          padding-top: 20px;
        }
      }
    }
  }
}
.mobile-profile-popup {
  .socials {
    align-items: flex-start !important;
    a {
      display: inline-block;
      margin: 0 10px;
      color: var(--gray-text-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      text-decoration: none;
      .social-item {
        width: 39px;
        height: 39px;
        margin: 15px auto 8px !important;
        border-radius: 100px;
        img {
          width: 100%;
        }
      }
      &:focus {
        color: #000c3d !important;
        .social-item {
          box-shadow: 0 0 8px 5px #000c3d !important;
        }
      }
    }
  }
  .link-focus {
    text-decoration: none;
    border-bottom: 1px solid var(--gray-text-color);
  }
}
.socials {
  align-items: flex-start !important;
  margin-top: 15px;
  a {
    display: inline-block;
    margin: 0 10px;
    color: var(--gray-text-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    .social-item {
      width: 39px;
      height: 39px;
      margin: 15px auto 8px !important;
      border-radius: 100px;
      img {
        width: 100%;
      }
    }
    &:focus {
      color: #000c3d !important;
      .social-item {
        box-shadow: 0 0 8px 5px #000c3d !important;
      }
    }
  }
}

.notifaction-managesetting {
  margin-top: 22px;
  @media (max-width: 991px) {
    margin-top: 15px;
  }
  .info-card {
    .card-content {
      .panel-info {
        .info-description {
          padding-right: 15px;
          .panel-tabs {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.hide-scroll {
  overflow: hidden;
}

.page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  > div {
    width: 100%;
    img:first-child {
      max-width: 280px;
      display: block;
      margin: 0 auto;
    }
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #001a80;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.boost-profile-popup {
  .model-body {
    margin-top: 20px;
    .rating-box {
      margin-bottom: 60px;
      .progressbar-wrapper {
        max-width: 420px;
        margin: 0 auto;
        .RSPBprogressBar {
          .RSPBstep {
            &:nth-child(5) {
              .transitionStep {
                &:after {
                  display: none !important;
                }
              }
            }
            .progress-item {
              margin-bottom: 0;
            }
          }
        }
      }
      .sub-header-primary {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        margin: 24px auto 0;
        max-width: 300px;
      }
    }
  }
}
.topic-post {
  h3 {
    margin: 10px 0 8px;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 500;
  }
  h4 {
    margin: 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
  }
}

.terms-popover-div {
  p {
    font-size: 20px !important;
    line-height: normal !important;
    + p {
      margin-top: 15px;
    }
  }
}

.webinar-section-title {
  .title-primary {
    &:first-child {
      border-bottom: 3.5px solid var(--secondary-color);
      padding-bottom: 4px;
    }
    a {
      color: var(--primary-color);
      text-decoration: none;
      &:focus {
        border-bottom: 3.5px solid var(--secondary-color);
      }
    }
  }
}
.keep-me-signin.tnc-wrapper {
  max-width: 100%;
  .tnc-link {
    text-align: left;
  }
}
.tnc-link {
  margin: 0;
  letter-spacing: 0;
  font-size: 15px;
  line-height: 16px;
  font-family: "TTCommons-Regular";
  color: var(--gray-text-color);
  font-weight: 400;
  a {
    color: var(--gray-text-color);
    text-decoration: none;
    border-bottom: 2px solid #8094ab;
    display: inline-block;
  }
}
.tnc-link.mt-25 {
  margin-top: 25px;
}

.collapsible-view {
  .custom-tab {
    display: flex;
    button {
      width: 100%;
      padding: 10px 10px 6px 10px !important;
    }
  }
}
.apply-job-popup {
  .MuiPaper-root {
    width: 100%;
    margin: 0;
    max-width: 480px;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
  }
  .MuiDialogContent-root {
    padding: 30px !important;
    width: 100% !important;
    scrollbar-width: thin;
    -ms-overflow-style: auto;
    overscroll-behavior: none;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: $color-grey transparent; /* scroll thumb and track */
    transition: scrollbar-color 0.3s ease-out;
    &::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: $color-blue-2 !important; /* color of the tracking area */
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-grey-4 !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: none;
      height: 150px !important;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }
    .MuiDialogContentText-root {
      padding: 0 !important;
    }
  }
}
.apply-job-popup-content {
  max-width: 320px;
  margin: 10px auto 0;
  form {
    .field-group {
      .textbox-label {
        margin: 0 0 7px 0 !important;
        padding: 5px 0 0 0 !important;
        line-height: 12px !important;
        color: var(--field-label-color) !important;
        display: block;
        text-align: center;
      }
      .custom-textbox {
        input {
          text-align: center;
        }
        input[placeholder="Link to LinkedIn"] {
          padding-left: 48px;
          padding-right: 32px;
        }
      }
      .react-custom-dropdown {
        > div {
          > div {
            justify-content: center;
            text-align: center;
            + div {
              position: absolute;
              right: 12px;
              top: 50%;
              transform: translateY(-50%);
              margin-top: 1px;
            }
            > div:first-child {
              padding-right: 18px;
              padding-left: 18px;
            }
          }
        }
      }
    }
    .fresh-upload-cv,
    .job-upload-cv,
    .upload-cv {
      text-align: center;
      .error-text {
        margin: 6px 0 0;
        font-size: 16px;
      }
      label {
        margin: 0 0 7px 0 !important;
        padding: 5px 0 0 0 !important;
        line-height: 12px !important;
        color: var(--field-label-color) !important;
        display: block;
        text-align: center;
        font-size: 15px;
      }
    }
    .fresh-upload-cv,
    .job-upload-cv {
      p.textbox-label {
        margin: 15px 10px 3px 10px;
      }
    }
    .textbox-with-icon {
      position: relative;
      margin: 25px 0 0 0;
      .textbox-icon {
        width: 20px;
        height: 20px;
        z-index: 1;
        position: absolute;
        left: 23px;
        top: 23px;
        transform: translateY(-50%);
      }
    }
    > div {
      z-index: 1;
    }
    .job-upload-cv {
      z-index: 0;
      .download-icon {
        cursor: pointer;
        margin: 10px 15px 4px 0px;
      }
    }
  }
}
.email-apply-popup {
  margin: 10px auto 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  .subTitle {
    color: #000c3d;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin-bottom: 30px;
    @media (max-width: 480px) {
      margin-bottom: 16px;
    }
  }
  .flex {
    justify-content: center;
    @media (max-width: 480px) {
      flex-wrap: wrap;
    }
    button {
      @media (max-width: 480px) {
        width: 100%;
        margin: 0;
      }
      &.bg-white {
        @media (max-width: 480px) {
          margin-top: 12px;
        }
      }
    }
  }
}
.rss-icon-align {
  width: 15px;
  height: 15px;
  margin-bottom: 0;
  margin-left: 4px;
  position: absolute;
  margin-top: 4px;
}
.search-card {
  position: relative;
  .clear-filter {
    position: absolute;
    right: 45px;
    top: 8px;
    text-align: center;
    .text-secondary {
      font-size: 15px;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      text-decoration: none;
      border-bottom: 1px solid var(--secondary-color);
    }
  }
}
p.error-text {
  color: var(--secondary-color);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  font-family: "TTCommons-Regular";
}
.popover-wrapper {
  .close-button {
    text-align: center;
    margin-top: 25px;
    button {
      width: 90px;
      height: 30px;
    }
  }
}

.employer-job {
  .MuiAvatar-img {
    object-fit: scale-down !important;
  }
}

.jobs-tutorial__footer-action {
  color: var(--gray-text-color);
  font-family: "TTCommons-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
  border-bottom: 1px solid var(--gray-text-color);
  text-decoration: none;
  &:focus {
    color: var(--primary-color);
    font-weight: 500;
    border-bottom: 2px solid var(--primary-color);
  }
}
.jobs-tutorial-dialog-modal {
  .MuiPaper-root {
    width: 100%;
    margin: 0;
    max-width: 700px;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
  }
  .MuiDialogContent-root {
    width: 100% !important;
    padding: 30px;
    margin: 0;

    .MuiDialogContentText-root {
      padding: 0 !important;
      margin: 0 !important;
      .makeStyles-dialogTitle-8 {
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 31px;
        text-align: center;
        color: var(--primary-color);
        max-width: 354px;
      }
      .jobs-tutorial__title {
        max-width: 440px;
      }
      .makeStyles-btnClose-11 {
        top: -5px;
        right: -5px;
      }
    }
  }
  .dialog-content {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: auto !important;
    padding-bottom: 0;
    @media (max-width: 991px) {
      margin-top: 16px;
    }
  }
}
.jobs-tutorial-dialog-modal__dialog-content {
  scrollbar-width: thin;
  -ms-overflow-style: auto;
  //   scrollbar-color: $color-blue $color-orange;
  // scroll-behavior: smooth;
  // scrollbar-color: $color-grey;
  // scrollbar-width: thin;
  // scrollbar-gutter: stable;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #d1dde5 transparent; /* scroll thumb and track */
  transition: scrollbar-color 0.3s ease-out;
  margin-bottom: 15px;
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar-track {
  background: #e5eaf7 !important; /* color of the tracking area */
  border-radius: 50px;
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar-thumb {
  background-color: #bfced8 !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none;
  height: 150px !important;
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar-corner {
  border-radius: 50%;
}

.jobs-tutorial-dialog-modal__dialog-content::-webkit-scrollbar-thumb:hover {
  border: 0;
}

.career-game-plan__wrapper {
  margin-top: 15px !important;
}

.new-msg-dialog {
  .MuiDialog-paperScrollPaper {
    height: 100%;
  }
  .dialog-content {
    > div {
      &:first-child {
        > div {
          &:nth-child(2) {
            margin-top: 16px;
            > div {
              > div {
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }
  }
}
.welcome-page {
  margin-bottom: 15px;
}

.faq-detail-paragraph {
  margin-bottom: 1rem;
}

.text-justify {
  text-align: justify;
}

.text-color-gray {
  color: var(--gray-text-color);
}

.video-bottom-spacing {
  margin-bottom: 1.5rem;
}

body {
  .nstudent-card-modal {
    > .MuiPaper-root {
      padding: 0 !important;
      max-width: 480px !important;
      width: 100%;
      margin: 0 15px !important;
      .MuiDialogContentText-root {
        padding: 0 !important;
      }
      .MuiDialogTitle-root {
        display: none;
        + .MuiDialogContent-root {
          margin: 0;
          margin: 0;
        }
      }
      button {
        margin-top: 16px !important;
      }
      .cancel-icon {
        right: 24px !important;
      }
    }
    .MuiDialog-container {
      .MuiDialog-paper {
        .MuiDialogContent-root {
          padding: 0 !important;
          overflow-x: hidden !important;
          overflow-y: auto !important;
          padding: 24px 24px !important;
        }
      }
    }
  }
}

.new-chat-dialog-modal {
  .MuiDialog-container {
    .groupchatsetp1 {
      .dialog-content {
        .form-group {
          max-width: 100%;
          width: auto;
          min-width: 300px;
          .ReactTags__tags {
            margin-bottom: 8px;
          }
        }
        > .mt-25 {
          margin-top: 16px !important;
        }
      }
    }
    .groupchatsetp2 {
      overflow-y: auto !important;
      overflow-x: hidden;
      scrollbar-width: thin;
      -ms-overflow-style: auto;
      overscroll-behavior: none;
      scrollbar-width: thin; /* "auto" or "thin" */
      scrollbar-color: transparent; /* scroll thumb and track */
      transition: scrollbar-color 0.3s ease-out;
      padding-right: 8px;
      &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
      }
      &::-webkit-scrollbar-track {
        background: #e5eaf7 !important; /* color of the tracking area */
        border-radius: 50px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #bfced8 !important; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: none;
        height: 150px !important;
      }
      &::-webkit-scrollbar-corner {
        border-radius: 50%;
      }
      &::-webkit-scrollbar-thumb:hover {
        border: 0;
      }
    }
  }
}

.chat-filter-button {
  background-color: #ffffff;
  border-radius: 10px 1px 10px 10px;
  display: inline-block;
  padding: 6px 16px 2px;
  border-radius: 100px;
  background: var(--secondary-color);
  color: #ffffff;
  font-family: "TTCommons-Medium";
  font-size: 15px;
  font-weight: 500;
  min-width: 80px;
}

.nempty-message-container {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  position: absolute;
  margin-top: 8px;
  top: 100%;
  width: 100%;
  z-index: 1;
  .dropdown-container__content {
    position: initial !important;
    max-height: 450px;
    overflow-y: scroll;
    -webkit-scrollbar-width: thin;
    -moz-scrollbar-width: thin;
    -ms-scrollbar-width: thin;
    scrollbar-width: thin;
    display: block;
    overflow-y: auto;
    margin: auto;
    width: 100%;
    overflow-x: hidden;
    padding: 24px;
    text-align: center;
    color: var(--primary-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    padding-bottom: 20px;
  }
}

.title-add-topic {
  cursor: pointer;
  background-color: #0030b5;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  &:focus {
    box-shadow: 0 0 8px 0 #000c3d !important;
    border-color: #000c3d !important;
  }
}

.recommended-network-see-more {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-text-color);
  color: #ffffff;
  border-radius: 15px;
  width: 20%;
  height: 30px;
  padding-top: 0.2rem;
  margin: auto;
  cursor: pointer;
}

.recommended-network-show-more-wrapper {
  display: flex;
  justify-content: center;
}

// profile module css
body {
  div {
    .profileContent {
      header {
        .layout-header-bar {
          padding: 0 40px;
          @media (max-width: 1366px) {
            padding: 0 30px;
          }
          @media (max-width: 991px) {
            padding: 0 24px !important;
          }
          @media (max-width: 767px) {
            padding: 0 16px !important;
          }
          .relative-div {
            > div {
              @media (max-width: 767px) {
                justify-content: center !important;
              }
            }
            .h1-maintitle {
              margin: 38px 0 28px;
              text-align: center;
              width: 100% !important;
              @media (max-width: 767px) {
                margin: 24px 0 24px;
              }
            }
          }
        }
      }
      .btn-signout {
        @media (max-width: 1366px) {
          right: 30px !important;
        }
        @media (max-width: 1000px) {
          top: 144px !important;
          right: 24px !important;
        }
        @media (max-width: 991px) {
          top: 126px !important;
        }
        @media (max-width: 767px) {
          right: auto !important;
          left: 50%;
          transform: translateX(-50%);
          top: 180px !important;
        }
      }
      .get-started-banner {
        border-radius: 10px;
        background-color: var(--primary-color);
        max-width: calc(100% - 80px);
        margin: 0 auto;
        padding: 80px 24px;
        @media (max-width: 1366px) {
          max-width: calc(100% - 60px);
        }
        @media (max-width: 991px) {
          max-width: calc(100% - 48px);
        }
        @media (max-width: 767px) {
          padding: 60px 24px;
          margin-top: 80px;
          max-width: calc(100% - 32px);
        }
        > div {
          display: flex;
          align-items: center;
          max-width: 1240px;
          margin: 0 auto;
          @media (max-width: 767px) {
            flex-wrap: wrap;
            justify-content: center;
          }
        }

        .img-left {
          width: 100%;
          text-align: right;
          padding-right: 65px;
          max-width: 516px;
          img {
            max-width: 100%;
          }
          @media (max-width: 1200px) {
            padding-right: 40px;
            max-width: 400px;
          }
          @media (max-width: 1024px) {
            padding-right: 30px;
            max-width: 350px;
          }
          @media (max-width: 767px) {
            max-width: 100%;
            text-align: center;
            margin-bottom: 24px;
          }
        }
        .description {
          width: calc(100% - 516px);
          color: #fff;
          @media (max-width: 1200px) {
            width: calc(100% - 400px);
          }
          @media (max-width: 1024px) {
            width: calc(100% - 350px);
          }
          @media (max-width: 767px) {
            width: 100%;
            text-align: center;
          }
          h1 {
            margin: 0;
            font-size: 42px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 38px;
            font-family: "TTCommons-DemiBold";
            @media (max-width: 1024px) {
              font-size: 34px;
            }
          }
          p {
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 28px;
            font-family: "TTCommons-Medium";
            margin: 12px 0 35px 0;
            @media (max-width: 1024px) {
              font-size: 24px;
              line-height: 24px;
              margin: 4px 0 24px 0;
            }
          }
          button {
            font-size: 22px;
            line-height: 21px;
            padding-top: 6px;
          }
        }
      }
      .MuiStepper-root {
        padding: 0;
        margin: 0px auto 30px !important;
        max-width: 452px;
        @media (max-width: 767px) {
          margin-bottom: 24px !important;
        }
        .MuiStep-root {
          padding: 0 !important;
          .MuiStepConnector-root {
            left: calc(-50% + 8px);
            right: calc(50% + 8px);
            .MuiStepConnector-line {
              border-color: #d1dde5 !important;
              border-width: 2px;
            }
          }
          &.MuiStep-completed {
            + .MuiStep-root {
              .MuiStepConnector-root {
                .MuiStepConnector-line {
                  border-color: rgb(0, 48, 181) !important;
                }
              }
            }
          }
          .MuiStepLabel-root {
            position: relative;
            z-index: 1;
            .MuiStepLabel-labelContainer {
              span {
                margin: 0;
                p {
                  margin: 8px 0 0 0 !important;
                  font-family: "TTCommons-Regular" !important;
                  font-weight: 400 !important;
                }
              }
            }
          }
        }
      }
      .after-main-title {
        @media (max-width: 767px) {
          margin-top: 24px !important;
        }
        .alert-icon {
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          left: 0 !important;
          position: initial !important;
          border: 0 !important;
          margin-left: 4px;
          img {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
      .middle-title-primary {
        @media (max-width: 480px) {
          display: inline-block !important;
          max-width: 320px;
        }
        .alert-icon {
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          left: 0 !important;
          position: initial !important;
          border: 0 !important;
          margin-left: 4px;
          @media (max-width: 480px) {
            display: inline-block !important;
            vertical-align: middle;
            margin-top: 0px !important;
          }
          img {
            width: 16px !important;
            height: 16px !important;
            margin-left: 0 !important;
          }
        }
      }
      .form-label,
      .textbox-label {
        display: block !important;
        width: 100% !important;
        text-align: left !important;
        margin: 0 0 4px 0 !important;
        padding: 0 0 0 8px !important;
        display: flex !important;
        align-items: center;
        .alert-icon {
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          left: 0 !important;
          position: initial !important;
          border: 0 !important;
          margin-left: 4px;
          img {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
      .align-items-row-end {
        align-items: flex-end;
      }
      .field-wrap {
        display: flex;
        align-items: flex-start;
        width: 100%;
        @media (max-width: 767px) {
          margin: 0 0 16px 0;
        }
        > div {
          margin-bottom: 0 !important;
          width: 100%;
        }
        .YourBG {
          > div {
            margin-bottom: 0 !important;
          }
        }
        .custom-textarea {
          .MuiInputBase-root {
            margin-bottom: 0;
          }
        }
        .form-control {
          input {
            padding: 20px 40px 18.3px 22px !important;
          }
        }
        .react-custom-dropdown {
          > div {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
      .add-info-link {
        border-top: 2px solid #f1f4f7;
        margin-top: 32px;
        padding-top: 32px;
        margin-bottom: 4px;
        @media (max-width: 767px) {
          margin-top: 16px;
          padding-top: 16px;
        }
        a {
          color: var(--secondary-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
          cursor: pointer;
          display: inline-block;
        }
      }
      .remove-info-link {
        a {
          color: var(--secondary-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
          cursor: pointer;
          display: inline-block;
          margin-top: 30px;
          @media (max-width: 767px) {
            margin-top: 16px;
          }
          img {
            vertical-align: middle;
            margin-left: 4px;
            width: 16px;
          }
        }
      }
      .step-last {
        margin-top: -10px;
        .card {
          padding: 30px 60px !important;
          @media (max-width: 767px) {
            padding: 16px 16px !important;
          }
          .alert-icon {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            left: 0 !important;
            position: initial !important;
            border: 0 !important;
            margin-left: 4px;
            top: 2px;
            position: relative !important;
            img {
              width: 16px !important;
              height: 16px !important;
            }
          }
          .network-text-div {
            p {
              border-bottom: 1px solid rgb(247, 97, 85);
              line-height: 18px;
              @media (max-width: 767px) {
                font-size: 16px;
                line-height: 16px;
              }
            }
          }
        }
      }
      form {
        .title-primary {
          padding: 0 !important;
          line-height: 28px !important;
        }
        .info-card {
          max-width: 670px;
          margin: 0 auto;
          @media (max-width: 767px) {
            width: calc(100% - 15px);
          }
          .card-content {
            padding: 30px 40px !important;
            @media (max-width: 767px) {
              padding: 16px 16px !important;
            }
            .MuiGrid-container {
              @media (max-width: 767px) {
                margin: 0;
                width: 100%;
              }
              .MuiGrid-item {
                @media (max-width: 767px) {
                  padding: 0;
                }
              }
            }
            .profile-info-content {
              padding: 0 !important;
              margin: 0 !important;
              .MuiGrid-container {
                @media (max-width: 767px) {
                  margin: 0;
                  width: 100%;
                }
                .MuiGrid-item {
                  @media (max-width: 767px) {
                    padding: 0;
                  }
                }
              }
              > div {
                padding: 0 !important;
              }
              .profile-upload-wrapper {
                > .form-label {
                  @media (max-width: 767px) {
                    justify-content: center;
                  }
                }
                .profile-upload {
                  > div {
                    margin-top: 0 !important;
                  }
                  .profile-img-wrap {
                    width: 60px !important;
                    height: 60px !important;
                    min-width: 60px !important;
                    border-radius: 100px !important;
                    overflow: hidden;
                  }
                  .profile-upload-link {
                    margin-top: 0 !important;
                    padding-left: 12px;
                    .upload-icon {
                      display: flex;
                      align-items: center;
                      .remove-icon {
                        .removeicon-center {
                          margin: 0 !important;
                          button {
                            width: auto;
                            padding: 2px 15px 0 !important;
                            height: 36px !important;
                            font-size: 18px;
                            overflow: hidden;
                            background-color: rgb(0, 48, 181) !important;
                          }
                        }
                      }
                      .remove-picture {
                        display: flex;
                        margin-left: 8px;
                        svg {
                          fill: var(--secondary-color);
                        }
                      }
                    }
                  }
                }
              }
              .resume-upload-wrapper {
                text-align: left;
                .resume-title {
                  font-family: "TTCommons-Regular";
                  color: var(--secondary-color);
                }
                .resume-name {
                  text-align: left;
                  color: var(--secondary-color);
                  border-bottom: 1px solid var(--secondary-color);
                  display: inline-block;
                  margin-left: 18px;
                  white-space: nowrap;
                  @media (max-width: 480px) {
                    margin-left: 0;
                  }
                }
                > .form-label {
                  @media (max-width: 767px) {
                    justify-content: center;
                  }
                }
                .profile-upload {
                  > div {
                    margin-top: 0 !important;
                  }
                  .profile-img-wrap {
                    width: 60px !important;
                    height: 60px !important;
                    min-width: 60px !important;
                    border-radius: 100px !important;
                    overflow: hidden;
                  }
                  .profile-upload-link {
                    margin-top: 0 !important;
                    padding-left: 12px;
                    .upload-icon {
                      display: flex;
                      flex-wrap: wrap;
                      .remove-icon {
                        .removeicon-center {
                          margin: 0 !important;
                          button {
                            width: auto;
                            padding: 2px 15px 0 !important;
                            height: 36px !important;
                            font-size: 18px;
                            overflow: hidden;
                            background-color: rgb(0, 48, 181) !important;
                          }
                        }
                      }
                      .remove-picture {
                        display: flex;
                        margin-left: 8px;
                        svg {
                          fill: var(--secondary-color);
                        }
                      }
                    }
                  }
                }
              }
              .linked-in {
                @media (max-width: 767px) {
                  margin-bottom: 16px;
                  width: 100% !important;
                }
                button {
                  width: 100% !important;

                  > div {
                    margin: 0 !important;
                    padding: 0 !important;
                    height: auto !important;
                    border: 0;
                    box-sizing: border-box;
                    background: transparent;
                    width: 100% !important;
                    .linkedin-button {
                      width: 100% !important;
                      height: 60px;
                      min-height: 60px;
                      padding: 0 5px;
                      margin: 0;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      border: 2px solid var(--gray-outline-color) !important;
                      border-radius: 100px;
                      padding-top: 1px;
                      span {
                        margin-right: 12px;
                        margin-right: 12px;
                        font-weight: bold;
                        font-family: "TTCommons-DemiBold";
                        font-size: 30px;
                        line-height: 30px;
                        width: 48px;
                        height: 48px;
                        background: #0073b1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100px;
                        padding-top: 6px;
                      }
                      p {
                        margin: 0;
                        font-family: "TTCommons-Medium";
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 17px;
                        text-align: center;
                        color: #102fae;
                        padding-top: 4px;
                      }
                    }
                  }
                }
              }
              .form-label,
              .textbox-label {
                display: block !important;
                width: 100% !important;
                text-align: left !important;
                margin: 0 0 4px 0 !important;
                padding: 0 0 0 8px !important;
                display: flex !important;
                align-items: center;
                .alert-icon {
                  display: flex !important;
                  align-items: center !important;
                  justify-content: center !important;
                  left: 0 !important;
                  position: initial !important;
                  border: 0 !important;
                  margin-left: 4px;
                  img {
                    width: 16px !important;
                    height: 16px !important;
                  }
                }
              }
              &.step2 {
                .remove-info-link {
                  a {
                    justify-content: center;
                  }
                }
                .field-wrap {
                  > div {
                    > div {
                      margin-bottom: 0 !important;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .profile-summary-detail-wrapper {
        @media (max-width: 767px) {
          max-width: calc(100% - 15px);
          margin: 0 auto;
        }
        .MuiAccordion-root {
          border-radius: 10px;
          background-color: #ffffff;
          box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
          .MuiAccordionSummary-root {
            display: flex;
            align-items: center;
            padding: 34px 40px 30px;
            min-height: auto;
            background: #fff !important;
            border-radius: 10px;
            @media (max-width: 1200px) {
              padding: 24px 24px 24px;
            }
            @media (max-width: 1200px) {
              padding: 16px;
            }
            .MuiAccordionSummary-content {
              padding: 0;
              margin: 0;
              > div {
                padding: 0 !important;
              }
              .accordian-title-wrapper {
                display: flex;
                align-items: center;
                .title-primary {
                  margin: 0 !important;
                  font-size: 30px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 28px;
                  @media (max-width: 767px) {
                    font-size: 24px;
                  }
                  + div {
                    margin: -4px 0px 0 20px !important;
                    height: auto !important;
                    width: auto !important;
                    border-radius: 100px !important;
                    padding: 12px 12px 6px !important;
                    height: 36px !important;
                    @media (max-width: 767px) {
                      margin: -4px 0px 0 8px !important;
                      padding: 5px 12px 2px !important;
                      height: 30px !important;
                    }
                    p {
                      margin: 0 !important;
                      font-size: 22px !important;
                      font-weight: 500 !important;
                      letter-spacing: 0;
                      line-height: 16px;
                      text-align: center !important;
                      font-family: "TTCommons-Medium" !important;
                      @media (max-width: 767px) {
                        font-size: 18px !important;
                      }
                      svg {
                        margin: 0 0 0 6px !important;
                        position: relative;
                        top: -3px;
                        font-size: 24px !important;
                        @media (max-width: 767px) {
                          font-size: 18px !important;
                        }
                      }
                    }
                  }
                }
              }
            }
            .MuiIconButton-root {
              padding: 0;
              margin: 0;
              width: 15px !important;
              height: 15px !important;
              span {
                svg {
                  font-size: 24px;
                }
              }
            }
          }
          .MuiCollapse-container {
            padding-bottom: 0 !important;
            border-radius: 0 0 10px 10px;
            .MuiAccordionDetails-root {
              padding: 0 40px 32px 40px !important;
              @media (max-width: 1200px) {
                padding: 24px 24px 24px !important;
              }
              @media (max-width: 1200px) {
                padding: 16px !important;
              }
              > div {
                padding: 0 !important;
                width: 100%;
              }
            }
            .MuiGrid-container {
              @media (max-width: 767px) {
                margin: 0;
                width: 100%;
              }
              .MuiGrid-item {
                @media (max-width: 767px) {
                  padding: 0;
                }
              }
            }
            .after-main-title {
              .alert-icon {
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                left: 0 !important;
                position: initial !important;
                border: 0 !important;
                margin-left: 4px;
                img {
                  width: 16px !important;
                  height: 16px !important;
                }
              }
            }
            .middle-title-primary {
              @media (max-width: 480px) {
                display: inline-block !important;
                max-width: 320px;
              }
              .alert-icon {
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                left: 0 !important;
                position: initial !important;
                border: 0 !important;
                margin-left: 4px;
                @media (max-width: 480px) {
                  display: inline-block !important;
                  vertical-align: middle;
                  margin-top: -2px !important;
                }
                img {
                  width: 16px !important;
                  height: 16px !important;
                  margin-left: 0 !important;
                }
              }
              &.mt-32 {
                padding-left: 8px !important;
                margin-top: 24px;
                margin-bottom: 0 !important;
              }
            }
            .form-label,
            .textbox-label {
              display: block !important;
              width: 100% !important;
              text-align: left !important;
              margin: 0 0 4px 0 !important;
              padding: 0 0 0 8px !important;
              display: flex !important;
              align-items: center;
              .alert-icon {
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                left: 0 !important;
                position: initial !important;
                border: 0 !important;
                margin-left: 4px;
                img {
                  width: 16px !important;
                  height: 16px !important;
                }
              }
            }
            .align-items-row-end {
              align-items: flex-end;
            }
            .field-wrap {
              display: flex;
              align-items: flex-start;
              width: 100%;
              @media (max-width: 767px) {
                margin: 0 0 16px 0;
              }
              > div {
                margin-bottom: 0 !important;
                width: 100%;
              }
              .YourBG {
                > div {
                  margin-bottom: 0 !important;
                }
              }
              .custom-textarea {
                .MuiInputBase-root {
                  margin-bottom: 0;
                }
              }
              .form-control {
                input {
                  padding: 20px 40px 18.3px 22.18px !important;
                }
              }
            }
            .add-info-link {
              border-top: 2px solid #f1f4f7;
              margin-top: 32px;
              padding-top: 32px;
              margin-bottom: 0;
              text-align: left;
              @media (max-width: 767px) {
                margin-top: 16px;
                padding-top: 16px;
              }
              a {
                color: var(--secondary-color);
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 19px;
                cursor: pointer;
                display: inline-block;
                span {
                  font-size: 24px;
                  vertical-align: middle;
                  margin-left: 2px;
                  position: relative;
                  top: 1px;
                  font-family: "TTCommons-Regular";
                  font-weight: 400;
                }
              }
            }
            .remove-info-link {
              text-align: left;
              a {
                color: var(--secondary-color);
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 19px;
                text-align: left;
                cursor: pointer;
                display: inline-block;
                margin-top: 32px;
                @media (max-width: 767px) {
                  margin-top: 16px !important;
                }
                img {
                  vertical-align: middle;
                  margin-left: 4px;
                  width: 18px;
                  margin-top: -2px;
                }
              }
            }
            .profile-upload-wrapper {
              > .form-label {
                @media (max-width: 767px) {
                  justify-content: center;
                }
              }
              .profile-upload {
                > div {
                  margin-top: 0 !important;
                }
                .profile-img-wrap {
                  width: 60px !important;
                  height: 60px !important;
                  min-width: 60px !important;
                  border-radius: 100px !important;
                  overflow: hidden;
                }
                .profile-upload-link {
                  margin-top: 0 !important;
                  padding-left: 12px;
                  .upload-icon {
                    display: flex;
                    align-items: center;
                    .remove-icon {
                      .removeicon-center {
                        margin: 0 !important;
                        button {
                          width: auto;
                          padding: 2px 15px 0 !important;
                          height: 36px !important;
                          font-size: 18px;
                          overflow: hidden;
                          background-color: rgb(0, 48, 181) !important;
                        }
                      }
                    }
                    .remove-picture {
                      display: flex;
                      margin-left: 8px;
                      svg {
                        fill: var(--secondary-color);
                      }
                    }
                  }
                }
              }
            }
            .resume-upload-wrapper {
              text-align: left;
              @media (max-width: 767px) {
                margin-bottom: 16px;
              }
              .resume-name {
                text-align: left;
                color: var(--secondary-color);
                border-bottom: 1px solid var(--secondary-color);
                display: inline-block;
                margin-left: 0;
                white-space: nowrap;
                @media (max-width: 480px) {
                  margin-left: 0;
                }
              }
              > .form-label {
                @media (max-width: 767px) {
                  justify-content: center;
                }
              }
              .profile-upload {
                > div {
                  margin-top: 0 !important;
                }
                .profile-img-wrap {
                  width: 60px !important;
                  height: 60px !important;
                  min-width: 60px !important;
                  border-radius: 100px !important;
                  overflow: hidden;
                }
                .profile-upload-link {
                  margin-top: 0 !important;
                  padding-left: 12px;
                  .upload-icon {
                    display: flex;
                    align-items: center;
                    .remove-icon {
                      .removeicon-center {
                        margin: 0 !important;
                        button {
                          width: auto;
                          padding: 2px 15px 0 !important;
                          height: 36px !important;
                          font-size: 18px;
                          overflow: hidden;
                          background-color: rgb(0, 48, 181) !important;
                        }
                      }
                    }
                    .remove-picture {
                      display: flex;
                      margin-left: 8px;
                      svg {
                        fill: var(--secondary-color);
                      }
                    }
                  }
                }
              }
            }
            .linked-in {
              @media (max-width: 767px) {
                margin-bottom: 16px;
              }
              button {
                width: 100%;
                > div {
                  margin: 0 !important;
                  padding: 0 !important;
                  height: auto !important;
                  border: 0;
                  box-sizing: border-box;
                  background: transparent;
                  .linkedin-button {
                    height: 60px;
                    min-height: 60px;
                    padding: 0 5px;
                    margin: 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border: 2px solid var(--gray-outline-color) !important;
                    border-radius: 100px;
                    padding-top: 1px;
                    span {
                      margin-right: 12px;
                      margin-right: 12px;
                      font-weight: bold;
                      font-family: "TTCommons-DemiBold";
                      font-size: 30px;
                      line-height: 30px;
                      width: 48px;
                      height: 48px;
                      background: #0073b1;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 100px;
                      padding-top: 6px;
                    }
                    p {
                      margin: 0;
                      font-family: "TTCommons-Medium";
                      font-size: 18px;
                      font-weight: 500;
                      letter-spacing: 0;
                      line-height: 17px;
                      text-align: center;
                      color: #102fae;
                      padding-top: 4px;
                    }
                  }
                }
              }
            }
            .step2 {
              .field-wrap {
                > div {
                  > div {
                    margin-bottom: 0 !important;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .step-detail1,
        .step-detail2,
        .step-detail3 {
          .MuiCollapse-container {
            .profile-info-content {
              .MuiGrid-container {
                .MuiGrid-item {
                  @media (max-width: 1100px) {
                    max-width: 50%;
                    flex-basis: 50%;
                  }
                  @media (max-width: 767px) {
                    max-width: 100%;
                    flex-basis: 100%;
                  }
                }
              }
            }
          }
        }
        .step-detail4 {
          .MuiCollapse-container {
            .MuiGrid-container {
              .MuiGrid-item {
                @media (max-width: 1100px) {
                  max-width: 50%;
                  flex-basis: 50%;
                }
                @media (max-width: 767px) {
                  max-width: 100%;
                  flex-basis: 100%;
                }
              }
            }
          }
        }
        .step-detail-last {
          > div {
            margin-top: 40px !important;
            .alert-icon {
              display: flex !important;
              align-items: center !important;
              justify-content: center !important;
              left: 0 !important;
              position: initial !important;
              border: 0 !important;
              margin-left: 4px;
              margin-top: -3px;
              img {
                width: 16px !important;
                height: 16px !important;
              }
            }
            > div {
              > div {
                > div {
                  &:nth-child(2) {
                    > div {
                      @media (max-width: 767px) {
                        width: 100% !important;
                        justify-content: center !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.nlogin-profile-popup {
  .MuiDialog-paper {
    width: 100%;
    max-width: 480px;
    border-radius: 10px;
    box-shadow: var(--card-box-shadow);
    @media (max-width: 767px) {
      margin: 0 auto !important;
    }
    @media (max-width: 600px) {
      max-width: 90% !important;
    }
  }
}

.loggedin-profile-modal {
  padding: 30px;
  @media (max-width: 767px) {
    padding: 16px;
    max-width: 100%;
  }
  .m-header {
    margin-bottom: 8px;
    .modal-title {
      max-width: 100%;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
  }
  .p-info {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin: 0 0 16px 0;
  }
  .m-body {
    max-width: 326px;
    margin: 0 auto;
    .items {
      + .items {
        margin-top: 15px;
      }
      .items2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > .field-wrap {
          width: calc(50% - 4px) !important;
          .Mui-error:first-child {
            position: absolute;
            left: 23%;
          }
        }
      }
    }
    .profile-upload-wrapper {
      > .form-label {
        @media (max-width: 767px) {
          justify-content: center;
        }
      }
      .profile-upload {
        > div {
          margin-top: 0 !important;
        }
        .MuiAvatar-root {
          width: 60px !important;
          height: 60px !important;
        }
        .profile-img-wrap {
          width: 60px !important;
          height: 60px !important;
          min-width: 60px !important;
          border-radius: 100px !important;
          overflow: hidden;

          .profile-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @media (max-width: 767px) {
            margin-right: 0 !important;
          }
        }
        .profile-upload-link {
          margin-top: 0 !important;
          padding-left: 0;
          @media (max-width: 767px) {
            padding-left: 0px;
          }
          .upload-icon {
            margin: 4px 0 0 0;
            @media (max-width: 767px) {
              margin-top: 16px;
            }
            .remove-icon {
              .btn-upload {
                font-size: 15px;
                line-height: 14px;
              }
              .removeicon-center {
                margin: 0 !important;
                button {
                  width: auto;
                  padding: 2px 15px 0 !important;
                  height: 36px !important;
                  font-size: 18px;
                  overflow: hidden;
                  background-color: rgb(0, 48, 181) !important;
                }
              }
            }
          }
        }
      }
    }
    .form-label,
    .textbox-label {
      display: flex;
      justify-content: center;
      padding: 5px 0 0 0;
      line-height: 14px;
      margin: 0 0 6px 8px;
      font-size: 15px;
      color: var(--field-label-color);
      font-family: "TTCommons-Regular";
      svg {
        cursor: pointer;
        margin: -4px 0 0 6px;
      }
      .alert-icon {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        left: 0 !important;
        position: initial !important;
        border: 0 !important;
        margin-left: 4px;
        img {
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
    .field-wrap {
      display: flex;
      align-items: flex-start;
      width: 100%;
      @media (max-width: 767px) {
        margin: 0 0 16px 0;
      }
      .field-group {
        > div {
          margin-bottom: 0 !important;
        }
        .textbox-label {
          display: block;
          width: 100%;
          text-align: center;
          margin-left: 0 !important;
        }
        .react-custom-dropdown {
          > div {
            text-align: center;
            > div {
              justify-content: center;
            }
          }
        }
      }
      > div {
        margin-bottom: 0 !important;
        width: 100%;
      }
      .YourBG {
        > div {
          margin-bottom: 0 !important;
        }
        .edit-link {
          color: var(--link-color);
          font-family: "TTCommons-Medium";
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 13px;
          text-align: center;
          text-decoration: none;
          border-bottom: 1px solid var(--link-color);
          padding-top: 4px;
          top: 2px;
          &.shift-right {
            right: -15px;
          }
        }
      }
      .custom-textarea {
        .MuiInputBase-root {
          margin-bottom: 0;
        }
      }
      .form-control {
        input {
          text-align: center;
        }
      }
    }
    .linked-in {
      button {
        background: #fff !important;
        box-shadow: var(--card-box-shadow) !important;
        color: var(--field-label-color);
        padding: 12px 20px 12px;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Medium";
        border-radius: 100px;
        text-decoration: none;
        width: 100%;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: initial;
        padding-top: 0px !important;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
        .linkedin-button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
            vertical-align: middle;
            margin: -4px 12px 0 0px;
          }
          p {
            color: var(--field-label-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            margin: 0;
            padding-top: 4px;
            min-width: 124px;
            text-align: center;
          }
        }
      }
    }
    .google-connect-first-login-sso-wrapper {
      .google-sso-connect-v1 {
        justify-content: center;
        .google-sso-connect-v1-img {
          img {
            vertical-align: middle;
            margin: 1px 12px 0 0px;
          }
        }
        .google-sso-connect-v1-text {
          margin: 0;
          padding-top: 4px;
          min-width: 124px;
          text-align: center;
        }
      }
    }
    .action-btns-wrapper {
      margin-top: 30px;
      display: flex;
      button {
        padding: 18px 20px 12px;
        width: 100%;
        font-family: "TTCommons-Regular";
      }
      .shadow-btn {
        background: #fff !important;
        box-shadow: var(--card-box-shadow) !important;
        text-decoration: none;
        color: var(--field-label-color);
        width: 100%;
        padding: 18px 20px 12px;
        height: auto;
        min-height: 50px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Regular";
        border-radius: 100px;
        text-decoration: none;
        transition: all 0.3s;
        display: inline-block;
        text-transform: initial;
        margin-right: 4px;
        + button {
          margin-left: 4px;
        }
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
        img {
          width: 8px;
          transform: rotate(180deg);
          vertical-align: middle;
          margin: -2px 8px 0 0;
          &.plus-icon {
            width: 14px;
          }
        }
      }
    }
    .mandatory-fields-description-text {
      margin: 20px 0 0 0;
      color: var(--field-label-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
    }
  }
  .profile-info {
    + div {
      @media (max-width: 767px) {
        flex-wrap: wrap;
        padding-top: 0 !important;
      }
    }
  }
}
.step-wrapper {
  @media (max-width: 767px) {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    max-width: 90%;
    margin: 0 auto 0px !important;
    > div {
      p {
        color: var(--primary-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        margin: 0;
        font-weight: 400;
        padding: 0px 0 8px 0;
      }
    }
  }
}
.step-detailtop-buttons-wrapper {
  @media (max-width: 1024px) {
    position: initial !important;
    justify-content: center !important;
    margin-bottom: 16px !important;
  }
  @media (max-width: 600px) {
    margin-top: -60px;
  }
}

body {
  .step-detail-profile {
    div {
      .MuiDialog-paper {
        width: 100% !important;
        max-width: 480px !important;
        padding: 0px !important;

        .MuiDialogContent-root {
          padding: 30px 28px;
          box-sizing: border-box;
          width: 100% !important;
          > div {
            padding: 0 !important;
            margin: 0 !important;
            #modal-title {
              display: none;
            }
            .profile-img-wrap {
              margin: 0 !important;
              width: 100px !important;
              height: 100px !important;
              border-radius: 100px !important;
              overflow: hidden !important;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
            .name {
              h2 {
                color: var(--primary-color);
                font-family: "TTCommons-Medium";
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 31px;
                text-align: center;
                margin: 20px 0 0 0;
              }
            }
            .tagwrap {
              > div {
                margin: 0 !important;
                padding: 0 !important;
                background-color: transparent !important;
                p {
                  padding: 7px 12px 0;
                  color: var(--primary-color) !important;
                  font-family: "TTCommons-Medium";
                  font-size: 15px !important;
                  letter-spacing: 0 !important;
                  line-height: 15px !important;
                  text-align: center !important;
                  min-height: 24px !important;
                  font-weight: 500;
                  background-color: #e5eaf7;
                  border-radius: 100px;
                  min-width: 75px;
                }
                + div {
                  margin-left: 8px !important;
                }
              }
            }
            .org-name {
              margin-top: 16px;
              p {
                margin: 0 !important;
                display: flex;
                align-items: center;
                color: var(--primary-color) !important;
                font-family: "TTCommons-Medium";
                font-size: 18px !important;
                font-weight: 500 !important;
                letter-spacing: 0 !important;
                line-height: 19px !important;
                text-align: center !important;
                img {
                  margin: -6px 6px 0 0 !important;
                }
              }
            }
            .staff-name {
              margin-top: 16px;
              p {
                margin: 0 !important;
                display: flex;
                align-items: center;
                color: var(--primary-color) !important;
                font-family: "TTCommons-Medium";
                font-size: 18px !important;
                font-weight: 500 !important;
                letter-spacing: 0 !important;
                line-height: 19px !important;
                text-align: center !important;
                justify-content: center;
                img {
                  margin: -6px 6px 0 0 !important;
                }
              }
            }
            .country-city-wrap {
              margin-top: 16px;
              > div {
                display: flex;
                align-items: center;
                img {
                  margin: -4px 6px 0 0 !important;
                }
                p {
                  margin: 0 !important;
                  color: var(--primary-color) !important;
                  font-family: "TTCommons-Medium";
                  font-size: 18px !important;
                  font-weight: 500 !important;
                  letter-spacing: 0 !important;
                  line-height: 19px !important;
                  text-align: center !important;
                }
              }
            }
            .description {
              margin-top: 16px;
              p {
                margin: 0 !important;
                color: var(--primary-color) !important;
                font-family: "TTCommons-Regular";
                font-size: 18px !important;
                font-weight: 500 !important;
                letter-spacing: 0 !important;
                line-height: 19px !important;
                text-align: center !important;
                a {
                  white-space: nowrap;
                }
              }
            }
            .social-icon {
              display: flex;
              align-items: center;
              flex-direction: column;
              margin-top: 16px;
              a {
                height: 39px;
                width: 39px;
                border-radius: 100px !important;
                box-shadow: 0 1px 11px 2px rgba(209, 221, 229, 0.4);
                overflow: hidden;
                margin: 0 0 4px 0;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              span {
                color: var(--gray-text-color);
                font-family: "TTCommons-Regular";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

.fc-day-span-appointment {
  height: 52px;
  width: 52px;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 48, 181, 0.2);
  cursor: pointer;
}

.fc-day-span-appointment.selectedDate {
  background: var(--primary-color);
  color: #fff;
  padding: 6px 0 0 0;
}
.fc-day-span-appointment.selectedDate.selectedAppointment {
  background: rgb(247, 97, 85);
}
.fc-day-span-non-appointment {
  height: 52px;
  width: 52px;
  display: block;
  align-items: center;
  display: flex;
  justify-content: center;
}

/* .fc-day-today .fc-daygrid-day-number {
  height: 52px !important;
  width: 52px !important;
} */

.timezoneSelect {
  width: 325px;
  margin-top: 20px;
}

.timezoneDropdown {
  width: 19vw;
  margin-top: 10px;
}

.header-link-with-dot {
  display: flex;
  align-items: center;
  svg {
    fill: var(--primary-color);
    width: 10px;
    height: 10px;
    margin-left: 0.5rem;
    margin-bottom: 3px;
  }
}

.notification-overlay {
  position: fixed;
  left: 100%;
  top: 58px;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 148, 171, 0.2);
  transition: all 0.1s;
  -webkit-transition: all 0.1s !important;
  z-index: 1050;
  &.notification-overlay-open {
    left: 0;
  }
}
.notifications-drawer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 56px;
  right: -595px;
  padding: 24px 30px;
  max-width: 595px;
  background: #ffffff;
  transition: all 0.5s;
  -webkit-transition: all 0.5s !important;
  border: 2px solid #ebf1f7;
  background-color: #fafbfd;
  z-index: 99;
  overflow-y: auto;
  max-height: calc(100vh - 58px);
  z-index: 1051;
  @media (max-width: 1024px) {
    padding: 16px 16px;
  }
  @media (max-width: 600px) {
    width: 92%;
  }
  &.notifications-drawer-open {
    right: 0px;
  }
  .no-notifications {
    display: flex;
    align-items: center;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    padding-top: 0;
    word-break: break-word;
    margin: 0;
    img {
      margin-right: 15px;
      margin-top: -2px;
    }
  }
  .notification-dialog-info-panel-custom {
    .header-custom-width {
      width: 100% !important;
    }
    .info-panel {
      .info-panel-card {
        margin-bottom: 10px;
        .card-content {
          .MuiAvatar-root {
            width: 50px;
            height: 50px;
            min-width: 50px;
            img {
              width: 50px;
              height: 50px;
            }
          }
          .panel-title {
            @media (max-width: 767px) {
              font-size: 16px;
              line-height: 16px;
            }
          }
          .panel-sub-title {
            color: var(--field-label-color);
            margin-bottom: 0;
          }
          .panel-actions {
            opacity: 1;
            visibility: visible;
            align-items: center;
            margin-left: auto !important;
            .shadow-btn {
              background: #fff !important;
              box-shadow: var(--card-box-shadow) !important;
              color: var(--primary-dark-color);
              width: auto;
              padding: 10px 8px 6px;
              height: auto;
              height: 35px;
              min-height: 35px;
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
              font-family: "TTCommons-Medium";
              border-radius: 100px;
              text-decoration: none;
              transition: all 0.3s;
              display: inline-block;
              text-transform: initial;
              &:hover {
                box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
              }
            }
            .arrow-btn {
              width: 24px;
              height: 24px;
              min-width: auto;
              min-height: auto;
              background: transparent !important;
              padding: 0 !important;
              .content {
                min-width: auto;
                display: flex;
                img {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.notification-dialog-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .notification-dialog-title-view-all-wrapper {
    display: flex;
    flex-direction: row;
    button {
      margin: 0;
    }
  }
  .notification-dialog-setting-close-icon-wrapper {
    display: flex;
    align-items: center;
    .notification-dialogue-setting-icon-wrapper {
      svg {
        fill: var(--primary-color) !important;
        margin-right: 0.5rem;
        cursor: pointer;
      }
      &:focus {
        svg {
          box-shadow: 0 0 8px 4px #000c3d;
        }
      }
    }
  }
}

.notification-dialog-info-panel-custom {
  div > div > div > div > div {
    flex-wrap: nowrap !important;
  }
  img {
    object-fit: contain !important;
  }
}

.notification-dialog-see-all {
  display: flex;
  justify-content: center;
}

.no-notification-img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  img {
    width: 800px;
    margin-top: -75px;
    margin-bottom: 4rem;
  }
  span {
    margin-left: 20px;
    color: var(--primary-color);
    font-family: "TTCommons-Medium";
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
  }
}

.notification-settings-group-list {
  &-card {
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    overflow: hidden;
    padding: 31px 50px 29px 25px;
  }
  &-content {
    h3 {
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
  }
  &-options {
    margin-top: 5px;
    &-item {
      display: flex;
      align-items: center;
      input {
        margin: 0;
      }
      &-description {
        margin-top: 3px;
        margin-left: 0.5rem;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        line-height: 22px;
        color: var(--gray-text-color);
      }
    }
  }
}

.profile-accordian-mui {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  width: 100%;
  &::before {
    background-color: transparent !important;
  }
  .MuiAccordionDetails-root {
    display: block;
  }
}
.profile-accordian-mui__details {
  width: 100%;
  &-child {
    width: 100%;
  }
}

.profile-module-image-upload-modal {
  display: flex;
  justify-content: center;
}
.MuiStepper-root {
  display: flex;
  padding: 24px;
  justify-content: center;
  max-width: 520px;
  margin: 0 auto;
}
.city-autocomplete-input {
  box-sizing: border-box;
  border: 2px solid #d1dde5;
  border-radius: 45px;
  background-color: #ffffff;
  outline: none;
  text-align: left;
  padding: 22px;
  height: 60px;
  min-width: 230px;
  font-size: 18px;
  width: 100%;
  font-family: "TTCommons-Regular";
  font-weight: 500;
  color: var(--primary-color) !important;
  text-align: left;
  @media (max-width: 480px) {
    min-width: auto;
  }
  &::-webkit-input-placeholder {
    color: var(--gray-text-color) !important;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: var(--gray-text-color) !important;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: var(--gray-text-color) !important;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: var(--gray-text-color) !important;
    opacity: 1;
  }

  &:focus {
    box-shadow: 0 0 8px 0 #000c3d !important;
    border-color: #000c3d !important;
  }
}

.o-stepper-container {
  height: 100px;
  .MuiStepper-root {
    padding: 0;
    margin: 0px auto 40px !important;
    max-width: 452px;
    .MuiStep-root {
      padding: 0 !important;
      .MuiStepConnector-root {
        left: calc(-50% + 8px);
        right: calc(50% + 8px);
        .MuiStepConnector-line {
          border-color: #d1dde5 !important;
          border-width: 2px;
        }
      }
      &.MuiStep-completed {
        + .MuiStep-root {
          .MuiStepConnector-root {
            .MuiStepConnector-line {
              border-color: rgb(0, 48, 181) !important;
            }
          }
        }
      }
      .MuiStepLabel-root {
        position: relative;
        z-index: 1;
        .MuiStepLabel-labelContainer {
          span {
            margin: 0;
            p {
              margin: 8px 0 0 0 !important;
              font-family: "TTCommons-Regular" !important;
              font-weight: 400 !important;
            }
          }
        }
      }
    }
  }
  .MuiStepConnector-lineHorizontal {
    border-top-width: 2px !important;
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
}

.profile-module-card-content {
  padding: 30px 30px !important;
  @media screen and (max-width: 767px) {
    padding: 24px !important;
  }
}
.profile-linkedin-container {
  > button {
    width: 100% !important;
  }
}

.profile-module__wrapper {
  &.ac-profile-wrap {
    .profile-module-main-container {
      margin: 10px auto 75px !important;
      max-width: 1015px;
      padding: 0 15px 0px;
      @media (min-width: 1366px) {
        max-width: 1270px;
        padding: 0 0 0 0;
      }
    }
    .ac-profile-module-accordian-wrapper {
      margin-top: 8px;
      > div {
        margin-bottom: 20px;
        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
        .MuiAccordion-root {
          border-radius: 10px;
          background-color: #fff;
          box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
          .MuiAccordionSummary-root {
            display: flex;
            align-items: center;
            padding: 34px 40px 30px;
            min-height: auto;
            background: #fff !important;
            border-radius: 10px;
            @media (max-width: 767px) {
              padding: 20px 12px 16px;
            }
            .MuiAccordionSummary-content {
              padding: 0;
              margin: 0;
              > div {
                padding: 0 !important;
              }
            }
            .MuiIconButton-root {
              padding: 0;
              margin: 0;
              width: 15px;
              height: 15px;
              svg {
                font-size: 24px;
                color: var(--primary-dark-color) !important;
              }
            }
            &.focused-class {
              box-shadow: #000c3d 0px 0px 8px 0px !important;
              border-color: #000c3d !important;
              outline: none;
            }
            &.Mui-expanded {
              box-shadow: none !important;
              border-color: none !important;
            }
          }
          .MuiCollapse-container {
            .MuiAccordionDetails-root {
              padding: 0 40px 32px !important;
              @media (max-width: 767px) {
                padding: 0 12px 24px !important;
              }
              > div {
                padding: 0 !important;
                .removeicon-center {
                  @media (max-width: 767px) {
                    margin: 0 4px !important;
                  }
                }
                hr {
                  @media (max-width: 767px) {
                    margin-top: 16px;
                    margin-bottom: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.post-card-active {
  box-shadow: 0 0 8px 0 #000c3d !important;
  border: 2px solid #000c3d !important;
}

.custom-field-university-wrapper-abc-xyz {
  svg.css-19bqh2r {
    display: none !important;
  }
}
.graduation_date_flex_wrapper {
  display: flex;
  justify-content: space-between;
  .flex-item {
    p.textbox-label {
      justify-content: flex-start !important;
    }
    flex-grow: 1;
    max-width: 158px;
  }
}

.date-label {
  font-size: 15px;
  color: var(--primary-color);
  padding: 0;
  margin-bottom: 5px;
  sup {
    position: relative;
    left: -1px;
    top: 5px;
    font-size: 16px;
  }
}

.secondary-email-container {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    font-family: "TTCommons-Medium";
    font-weight: 500;
    font-size: 28px;
    text-align: center;
  }
  &.logo-container {
    padding-bottom: 20px;
    img {
      width: 200;
    }
  }
}

.modal-disconnect-google-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      width: 200px !important;
    }
  }
}

.make-primary-email-modal-footnotes {
  margin-top: 10px;
  text-align: center;
  p {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.google-sso-login-v2 {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  .google-sso-login-v2-content {
    cursor: pointer;
    background: #3284fc;
    height: 50px;
    border-radius: 45px;
    display: flex;
    align-items: flex-start;
    &:focus {
      opacity: 0.9;
      box-shadow: 0 0 0px 3px var(--primary-color),
        inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
    }
    .google-sso-login-v2-content-img {
      width: 20px !important;
      height: 20px !important;
      margin-top: 15px;
      margin-left: 24px;
      margin-right: 51.58px;
    }
    .google-sso-login-v2-content-text {
      color: white;
      font-family: "TTCommons-Medium" !important;
      font-size: 18px !important;
      line-height: 19px !important;
      margin-top: 18px;
    }
  }
}

.linkedin-sso-login-v2 {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  .linkedin-sso-login-v2-content {
    cursor: pointer;
    background: #0270ac;
    height: 50px;
    border-radius: 45px;
    display: flex;
    align-items: flex-start;
    .linkedin-sso-login-v2-content-img {
      width: 20px !important;
      height: 20px !important;
      margin-top: 15px;
      margin-left: 24px;
      margin-right: 51.58px;
    }
    .linkedin-sso-login-v2-content-text {
      color: white;
      font-family: "TTCommons-Medium" !important;
      font-size: 18px !important;
      line-height: 19px !important;
      margin-top: 18px;
    }
  }
}

.google-sso-connect-v1 {
  background: #fff !important;
  box-shadow: var(--card-box-shadow);
  color: var(--field-label-color);
  padding: 12px 20px 12px;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  font-family: "TTCommons-Medium";
  border-radius: 100px;
  text-decoration: none;
  width: 100%;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  cursor: pointer;
  .google-sso-connect-v1-img {
    img {
      width: 20px;
      vertical-align: middle;
    }
  }
  .google-sso-connect-v1-text {
    color: var(--field-label-color);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Regular";
    margin: 4px auto 0;
  }
  &:hover {
    box-shadow: 0 1px 14px 3px var(--gray-outline-color);
  }
  &:focus {
    box-shadow: #000c3d 0px 0px 8px 0px !important;
    border-color: #000c3d !important;
    outline: none;
  }
}

.google-connect-first-login-sso-wrapper {
  position: relative;
}

.dashboard-middle-wrapper {
  center {
    .post-body {
      margin: 20px 0 0 0;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      color: var(--f-light-gray-color);
    }
  }
}

.f-main-title {
  color: var(--primary-dark-color);
  font-family: "TTCommons-DemiBold";
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin: 0 0 15px 0;
  padding-top: 4px;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.f-main-title-with-button {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  min-height: 40px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    min-height: auto;
    margin-bottom: 16px !important;
  }
  .left-components {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .back-btn-wrapper {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 767px) {
      position: initial;
      margin: 0 0 16px 0;
      transform: none;
    }
    .shadow-btn {
      text-decoration: none;
      color: var(--field-label-color);
      img {
        width: 8px;
        transform: rotate(180deg);
        vertical-align: middle;
        margin: -2px 8px 0 0;
      }
    }
  }
  &.position-absolute {
    position: absolute;
    @media (max-width: 1367px) {
      position: inherit;
      margin: -15px 0 15px 0;
      justify-content: flex-start;
    }
    @media (max-width: 1367px) {
      justify-content: center;
      margin: 0 !important;
    }
    .back-btn-wrapper {
      position: unset;
      @media (max-width: 1367px) {
        transform: none;
      }
    }
  }
  .btn-wrapper-left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 767px) {
      position: initial;
      margin: 0 0 16px 0;
      transform: none;
    }
  }
  .btn-wrapper {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 767px) {
      position: initial;
      transform: none;
      margin-top: 15px;
      width: 100%;
      text-align: center;
    }
    button {
      .content {
        span {
          margin-left: 4px;
          font-size: 22px;
        }
      }
      + button {
        margin-left: 8px;
      }
    }
  }
  .shadow-btn {
    background: #fff !important;
    box-shadow: var(--card-box-shadow) !important;
    color: var(--primary-dark-color);
    width: auto;
    padding: 18px 20px 12px;
    height: auto;
    min-height: 50px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Medium";
    border-radius: 100px;
    text-decoration: none;
    transition: all 0.3s;
    display: inline-block;
    text-transform: initial;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
    .content {
      min-width: auto;
      display: flex;
      white-space: nowrap;
      img {
        margin: -4px 0 0 8px;
        width: 8px;
        vertical-align: middle;
        &.plus-icon {
          width: 14px;
        }
      }
    }
  }
  .back-to-btn {
    background: #fff !important;
    box-shadow: var(--card-box-shadow) !important;
    color: var(--field-label-color);
    width: auto;
    padding: 18px 20px 13px;
    height: auto;
    min-height: 50px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Medium";
    border-radius: 100px;
    text-decoration: none;
    transition: all 0.3s;
    display: inline-block;
    white-space: nowrap;
    text-transform: inherit;
    font-family: "TTCommons-Regular";
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
    .content {
      min-width: auto;
      img {
        margin: -1px 6px 0 0;
        width: 8px;
        vertical-align: middle;
        transform: rotate(180deg);
        &.plus-icon {
          width: 14px;
        }
      }
    }
  }
}

.back-btn-wrapper {
  .shadow-btn {
    background: #fff !important;
    box-shadow: var(--card-box-shadow) !important;
    text-decoration: none;
    color: var(--field-label-color);
    width: auto;
    padding: 18px 20px 12px;
    height: auto;
    min-height: 50px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Regular";
    border-radius: 100px;
    text-decoration: none;
    transition: all 0.3s;
    display: inline-block;
    text-transform: initial;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
    img {
      width: 8px;
      transform: rotate(180deg);
      vertical-align: middle;
      margin: -2px 8px 0 0;
      &.plus-icon {
        width: 14px;
      }
    }
  }
}

.categories-header-with-button {
  display: flex;
  align-items: flex-end;
  position: relative;
  justify-content: space-between;
  min-height: 50px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    min-height: auto;
    align-items: center;
  }
  .shadow-btn {
    background: #fff !important;
    box-shadow: var(--card-box-shadow) !important;
    color: var(--primary-dark-color);
    width: auto;
    padding: 16px 20px 12px;
    height: auto;
    min-height: 50px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Medium";
    border-radius: 100px;
    text-decoration: none;
    transition: all 0.3s;
    display: inline-block;
    text-transform: initial;
    @media (max-width: 767px) {
      padding: 12px 12px 8px;
      min-height: 35px;
    }
    &:hover {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
    .content {
      min-width: auto;
      display: flex;
      white-space: nowrap;
      img {
        margin: -4px 0 0 8px;
        width: 8px;
        vertical-align: middle;
        &.plus-icon {
          width: 14px;
        }
      }
    }
  }
}

.form-field-group {
  position: relative;
  label.textbox-label {
    color: var(--field-label-color);
    font-size: 15px;
    padding: 5px 0 0 0;
    margin: 0 0 6px 8px;
    line-height: 14px;
    display: block;
    font-family: "TTCommons-Regular";
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    .link-focus {
      margin-left: 8px;
      display: flex;
      margin-top: -4px;
    }
  }
  label.center {
    justify-content: center;
  }
}

.selectedfile-wrapper {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  font-family: "TTCommons-Medium";
  color: var(--primary-dark-color);
  display: flex;
  align-items: center;
  justify-content: center;
  .delete-btn {
    background-color: var(--icon_background);
    width: 35px;
    height: 35px;
    display: inline-block;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -4px 0 0 8px;
    &:hover {
      background-color: var(--icon_background_hover);
    }
  }
}

.pt-6 {
  padding-top: 6px;
}

.hide-element {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.course-page-body {
  .interstride-student-main-content-wrapper {
    margin-top: 30px !important;
  }
  .custom-container {
    padding-bottom: 0 !important;
    iframe {
      height: calc(100vh - 200px) !important;
      @media (max-width: 991px) {
        height: calc(100vh - 230px) !important;
      }
    }
  }
}

.action-menu {
  position: relative;
}

.group-members {
  .new-message-group-card {
    .card-content {
      padding: 20px;
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .user-avatar {
            margin-right: 10px;
          }
          .user-name {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            word-break: break-word;
            margin-right: 10px;
          }
          .user-action {
            background: var(--icon_background);
            display: flex;
            align-items: center;
            width: 35px;
            height: 35px;
            padding: 0 !important;
            min-width: 35px;
            transition: all 0.3s;
            border-radius: 100px;
            justify-content: center;
            margin-left: auto;
            cursor: pointer;
            a {
              img {
                vertical-align: middle;
              }
            }
            &:hover {
              background: var(--icon_background_hover);
            }
          }
        }
      }
      button {
        width: 100% !important;
      }
    }
  }
}

.group-setting-page {
  .card {
    box-shadow: var(--card-box-shadow);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 440px;
    margin: 0 auto;
    &__cropper-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .crop-btn {
        height: 50px !important;
        width: 200px !important;
        margin-top: 20px !important;
      }
    }
    label {
      color: var(--field-label-color);
      font-size: 15px;
      padding: 5px 0 0 0;
      margin: 0 0 6px 0px;
      line-height: 12px;
      display: block;
      font-family: "TTCommons-Regular";
      text-align: center;
      width: 100%;
    }
    .image-upload {
      .custom-avtar {
        .MuiAvatar-root {
          width: 90px;
          height: 90px;
          margin: 0 auto 8px;
        }
      }
    }
    .form-group {
      margin-top: 20px;
    }
    .group_name {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      > div {
        margin-bottom: 0px !important;
      }
      .custom-textbox {
        input {
          text-align: center;
          width: 286px;
        }
      }
    }
    .button-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
    .cancel-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      span {
        cursor: pointer;
        color: var(--gray-text-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        border-bottom: 1px solid var(--gray-text-color);
      }
    }
  }
}

.facelift-empty-boxwrap {
  padding: 20px;
  box-shadow: var(--card-box-shadow);
  background: #fff;
  border-radius: 10px;
  margin-bottom: 16px;
  h3 {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin: 4px 0 0 0;
    + p {
      margin-top: 4px;
    }
  }
  p {
    color: var(--gray-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    margin: 0 0 0 0;
  }
}

.f-search-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 0;
  @media (max-width: 860px) {
    flex-wrap: wrap;
    margin: 12px 0 12px 0;
  }
  .fsearch-box {
    display: flex;
    align-items: center;
    .field-group {
      margin-bottom: 0;
      margin-right: 10px;
      input {
        min-width: 219px;
        @media (max-width: 1024px) {
          min-width: auto;
        }
      }
    }
    .search-btn {
      &:focus {
        opacity: 0.9;
        box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
        border-radius: 45px;
      }
      img {
        cursor: pointer;
        vertical-align: middle;
      }
    }
  }
  .filter-item {
    display: flex;
    align-items: center;
    padding-left: 30px;
    @media (max-width: 860px) {
      width: 100%;
      padding: 0;
      justify-content: center;
      margin: 12px 0 0 0;
      flex-wrap: wrap;
    }
    label {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      color: var(--f-light-gray-color);
      margin: 0 15px 0 0;
      padding-top: 4px;
    }
    .filter-item-content {
      display: flex;
      align-items: center;
      margin: 4px 10px 4px 0;
      gap: 10px;
      @media (max-width: 767px) {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
      .item {
        border-radius: 40px;
        background-color: #ffffff;
        box-shadow: var(--card-box-shadow);
        padding: 10px 15px 4px;
        min-height: 35px;
        color: #000c3d;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        min-width: 96px;
        text-transform: capitalize;
        transition: all 0.3s;
        cursor: pointer;
        text-decoration: none;
        &.active {
          background-color: var(--primary-main-color);
          color: #fff;
        }
        &:hover {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
        &:focus {
          box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
        }
      }
    }
  }
  .clear-filter {
    margin-left: 10px;
  }
}

.f-search-wrapper-webinars {
  flex-direction: column;
  gap: 10px;
  .filter-item {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding-left: 0 !important;
    .filter-item-content {
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;
    }
  }
}

span.left-right-space-span {
  display: inline-block;
  margin: 0 8px;
  padding-top: 0px !important;
}

.visa-mainpage {
  .back-btn-wrapper {
    margin-bottom: 15px;
    @media (max-width: 991px) {
      margin-top: -14px;
    }
  }
}

.profile-step-form-progressbar {
  margin-bottom: 30px;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  .progressbar-wrapper {
    max-width: 420px;
    margin: 0 auto;
    padding: 0 0 0 0 !important;
    .RSPBprogressBar {
      .RSPBstep {
        &:nth-child(5) {
          .transitionStep {
            &:after {
              display: none !important;
            }
          }
        }
        .progress-item {
          width: 26px !important;
          padding: 0 0 0 0 !important;
          &::before {
            left: 11px !important;
          }
        }
        .progress-label {
          font-family: "TTCommons-Regular";
          color: var(--primary-dark-color);
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          @media (max-width: 767px) {
            margin-top: 8px;
            min-height: 34px !important;
            font-size: 13px;
            line-height: 15px;
          }
        }
      }
    }
    &.staffProgressBar {
      .RSPBprogressBar {
        .RSPBstep {
          &:nth-child(2) {
            .transitionStep {
              &:after {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

.react-custom-dropdown-optionvalue-center {
  > div {
    + div {
      > div {
        > div {
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
}
.transform-capitalize {
  text-transform: capitalize;
}
.capitalize {
  text-transform: capitalize;
}

.mobile-calendar-event-popup {
  > .MuiDialog-container {
    > .MuiPaper-root {
      width: 100%;
      margin: 0;
      max-width: 480px;
      box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
      @media (max-width: 480px) {
        width: 92%;
      }
      .MuiDialogContent-root {
        width: 100% !important;
        padding: 30px;
        .MuiDialogContentText-root {
          margin-bottom: 0;
          .mt-10 {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}

.selected-contry-content {
  .overview-wrapper {
    .title-primary {
      a:focus {
        img {
          box-shadow: 0 0 15px 0px #000c3d !important;
        }
      }
    }
  }
}

body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden !important;
  padding-top: 150px;
  @media (min-width: 480px) {
    padding-top: 108px;
  }
  @media (min-width: 767px) {
    padding-top: 112px;
  }
  @media (min-width: 991px) {
    padding-top: 68px;
  }
  @media (max-width: 990px) {
    padding-top: 88px;
  }
  /* scroll styling start */
  scrollbar-width: thin !important;
  -ms-overflow-style: auto;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #d1dde5 transparent; /* scroll thumb and track */
  transition: all ease-in-out 500ms;
  &:hover {
    overflow-y: auto;
    &::-webkit-scrollbar {
      height: 50px;
      transition: all ease-in-out 500ms;
    }
  }
  &::-webkit-scrollbar {
    width: 7px; /* width of the entire scrollbar */
    height: 50px;
    transition: all ease-in-out 500ms;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important; /* color of the tracking area */
    border-radius: 50px;
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 10px 0 10px 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d1dde5 !important; /* color of the scroll thumb */
    border-radius: 50px; /* roundness of the scroll thumb */
    height: 150px !important;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 50%;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0;
    background-color: #607790 !important; /* color of the scroll thumb */
  }
  /* scroll styling end */
}
.no-underline {
  text-decoration: none;
}
.br-50 {
  border-radius: 50px;
}

.bg-offwhite {
  background-color: #f1f4f7;
}

.bg-white {
  background-color: #ffffff;
}

.chat-text-area-facelift {
  width: 100%;

  textarea {
    width: 100%;
    max-height: 80px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: var(--field-label-color);
    font-family: "TTCommons-Regular", "Apple Color Emoji",
      "NotoColorEmoji-Regular";
    padding: 0 5px 0 0;
    scrollbar-width: thin;
    -ms-overflow-style: auto;
    overscroll-behavior: none;
    scrollbar-color: #d1dde5 transparent !important; /* scroll thumb and track */
    transition: scrollbar-color 0.3s ease-out;
    @media (max-width: 1366px) {
      font-size: 16px;
    }
    &::placeholder {
      color: $color-grey-2;
    }
    &::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-grey-4 !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: none;
      height: 10px !important;
    }
    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }
    &::-webkit-scrollbar-thumb:hover {
      border: 0;
    }
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  .MuiInput-underline:after {
    border-bottom: none !important;
  }
}
.d-none {
  display: none;
}
.dropdown-indicator > div {
  padding-left: 0px;
}

.graduation-year-group {
  .field-group {
    position: relative;
    .Mui-error {
      position: absolute;
      width: 100%;
      margin: 5px 0 0 0;
    }
  }
}

.group-jobs-listing {
  margin-top: 25px;
  max-height: 400px;
  // scroll override start
  overflow-y: auto !important;
  overflow-x: hidden;
  scrollbar-width: thin;
  -ms-overflow-style: auto;
  overscroll-behavior: none;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: transparent; /* scroll thumb and track */
  transition: scrollbar-color 0.3s ease-out;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #e5eaf7 !important; /* color of the tracking area */
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bfced8 !important; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: none;
    height: 150px !important;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 50%;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0;
  }
  @media (max-width: 600px) {
    margin-top: 0;
  }
  // scroll override end
  .search-list {
    .search-item-tags {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      row-gap: 10px;
      margin-bottom: 10px;
      .tag-item {
        border-radius: 100px;
        background-color: #f1f4f7;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        padding: 4px 10px 0;
        display: inline-block;
        white-space: nowrap !important;
      }
    }
    .search-item {
      display: flex;
      flex-wrap: no-wrap;
      align-items: center;
      @media (max-width: 600px) {
        flex-wrap: wrap;
        align-items: flex-start;
      }
      &.featured-job {
        .content {
          .header-primary {
            align-items: center;
          }
        }
      }
      .custom-rss-icon {
        height: 15px;
        width: 15px;
        position: absolute;
        margin-top: -49px;
        margin-left: -20px;
      }
      .avtar {
        margin-right: 10px;
        .MuiAvatar-root {
          width: 50px;
          height: 50px;
          border-radius: 100px;
        }
      }

      .apply-btn {
        margin-left: 10px;
        .nlink-btn {
          min-width: 80px;
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          padding: 2px 8px 0;
        }
        .bg-gray {
          color: #ffffff;
          background: var(--gray-color);
          &:hover {
            background: var(--gray-color-btn-hover);
          }
          &:disabled {
            background: #afbbc7;
            color: #ffff;
          }
        }
      }
      .content {
        margin-right: auto;
        max-width: calc(100% - 180px);
        @media (max-width: 600px) {
          max-width: calc(100% - 60px);
        }
        .header-primary {
          padding-top: 4px;
          line-height: 16px;
          span {
            white-space: normal;
            word-break: break-word;
          }

          span.inline-title {
            overflow: hidden;
            line-height: 17px;
            margin-bottom: 4px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          span.inline-title-flex {
            width: max-content;
          }
        }
        .star {
          margin: -3px 0 0 10px;
        }
        .tag-item {
          border-radius: 100px;
          background-color: #f1f4f7;
          color: #000c3d;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 21px;
          text-align: center;
          padding: 4px 8px 0;
          margin-left: 10px;
          margin-top: -4px;
          display: inline-block;
          white-space: nowrap !important;
        }
        + div {
          @media (max-width: 600px) {
            margin-left: 60px;
            margin-top: 4px;
          }
        }
      }
      .sub-header-primary {
        margin-bottom: -1px;
      }
      .text-grey {
        padding-top: 4px;
        line-height: 16px;
        @media (max-width: 600px) {
          font-size: 13px;
        }
      }
    }

    .card-actions-wrapper {
      display: flex;
      align-items: center;
      /* padding-left: 8px; */
      opacity: 0;
      visibility: hidden;
      padding-left: 0 !important;
      + div {
        margin-left: 8px;
      }
      &.show-actions {
        opacity: 1;
        visibility: visible;
      }
      @media (max-width: 1024px) {
        opacity: 1;
        visibility: visible;
      }
      .nlink-btn {
        width: auto;
        min-width: 80px;
        white-space: nowrap;
      }
      div {
        + div {
          margin-left: 12px;
          @media (max-width: 991px) {
            margin-left: 8px;
          }
        }
      }
    }
  }
  .card-wrapper {
    padding: 8px;
    @media (max-width: 600px) {
      padding: 0;
      margin: 0 0 10px 0;
    }
    .MuiPaper-root {
      padding: 18px 15px !important;
      transition: all 0.3s;
      min-height: 100px;
      display: flex;
      align-items: center;
      cursor: pointer;
      @media (max-width: 767px) {
        min-height: auto;
      }
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        .search-item {
          .card-actions-wrapper {
            opacity: 1;
            visibility: visible;
            transition: all 0.3s;
          }
        }
      }
      .MuiCardContent-root {
        padding: 0;
        width: 100%;
      }
    }
  }
}
.edit-option-container {
  padding-right: 10px;
  .edit-option {
    color: var(--link-color);
    font-family: "TTCommons-Medium";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    &:focus {
      color: var(--primary-color);
    }
  }
}
.a-input--profile__label {
  color: var(--field-label-color);
  font-size: 15px;
  padding: 5px 0 0 0;
  margin: 0 5px 7px 8px;
  line-height: 12px;
  display: block;
  font-family: "TTCommons-Regular";
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  a {
    text-decoration: none;
  }
}

.a-input-profile__password input {
  border: 2px solid var(--gray-color);
  border-radius: 45px;
  background-color: #ffffff;
  padding: 16.65px 20px 12.65px 20px;
  font-size: 34px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40px; // color: var(--gray-text-color);
  color: #000c3d !important;
  font-family: "TTCommons-Regular" !important;
  box-sizing: border-box;
  min-height: 50px;
  margin: 0;
  width: 100%;
  height: 50px;
  -webkit-text-security: disc !important;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--field-label-color) !important;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--field-label-color) !important;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--field-label-color) !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: var(--field-label-color) !important;
  }
  // --Focus style
  &:focus {
    border-color: var(--gray-color) !important;
  }
  &:hover {
    border-color: #bfced8;
  }
}

.a-input-profile__completed input {
  border: 2px solid var(--gray-color);
  border-radius: 45px;
}

// Responsive search & filter design - START
.collapsible-section {
  @media (max-width: 1081px) {
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 16px;
  }
  #collapsible-section-header {
    @media (max-width: 1081px) {
      display: flex;
      align-items: center;
      border-radius: 10px 10px 0 0;
      padding: 12px 8px 10px 16px;
      margin-bottom: 0;
    }
    h2 {
      @media (max-width: 1081px) {
        padding: 0;
        font-size: 18px;
        line-height: 18px;
        min-height: auto;
        padding-top: 4px;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        margin: 0;
      }
    }
    a {
      @media (min-width: 1024px) {
        display: none;
      }
      @media (max-width: 1081px) {
        margin-left: auto;
        padding: 4px 8px;
        display: flex;
        -webkit-tap-highlight-color: transparent;
      }
      svg {
        @media (max-width: 1081px) {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  #collapsible-section-search-filter {
    align-items: center;
    label {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      color: var(--f-light-gray-color);
      margin: 0 10px 16px 0;
      padding-top: 4px;
      @media (max-width: 1081px) {
        display: none;
      }
    }
    flex-wrap: wrap;
    .sort-by {
      margin-bottom: 16px;
    }
    @media (max-width: 1081px) {
      margin-top: 0;
      display: none;
    }
    > div {
      @media (max-width: 1081px) {
        border-radius: 0 0 10px 10px;
        box-shadow: none;
      }
    }
  }
  &--open {
    #collapsible-section-header {
      @media (max-width: 1081px) {
        border-bottom: 1px solid #d1dde5;
      }
    }
    #collapsible-section-search-filter {
      display: block;
    }
  }
}
// Responsive search & filter design - END

.a-marker-world-map {
  width: 100%;
  margin-bottom: 48px;
  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
  .chart-wrapper {
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    border-radius: 10px;
    overflow: hidden;
  }
  .google-visualization-tooltip {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 1px rgba(0, 12, 61, 0.15) !important;
    padding: 15px 15px 12px;
    border: 0 !important;
    ul {
      margin: 0 !important;
      padding: 0 !important;
    }
    .google-visualization-tooltip-item {
      margin: 0px !important;
      padding: 0 !important;
      + .google-visualization-tooltip-item {
        margin-top: 2px !important;
      }
      span {
        font-family: "TTCommons-Medium" !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        color: var(--primary-font-color) !important;
      }
    }
  }
}

.mui-table-wrapper {
  &__no-data {
    height: 400px;
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__header {
      color: #000c3d;
      letter-spacing: 0;
      margin: 0 0 0 0;
      font-weight: 500;
      font-size: 22px;
      font-family: "TTCommons-Medium";
    }
    &__body {
      color: #000c3d;
      letter-spacing: 0;
      margin: 8px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      font-family: "TTCommons-Regular";
    }
  }
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
    padding: 0 10px 0 10px;
    thead {
      tr {
        th {
          color: #000c3d;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          padding: 4px 12px 20px;
          border: 0;
          white-space: nowrap;
          &:first-child {
            padding-left: 30px;
            @media (max-width: 767px) {
              padding-left: 16px;
            }
          }
          &:last-child {
            padding-right: 30px;
            @media (max-width: 767px) {
              padding-right: 16px;
            }
          }
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 14px;
            padding: 4px 12px 8px;
          }
        }
      }
    }
    tbody {
      border-radius: 10px;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      tr {
        td {
          color: #000c3d;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 19px;
          padding: 26px 12px 19px;
          border: 0;
          background-color: rgba(209, 221, 229, 0.3);
          word-break: initial;
          &:first-child {
            padding-left: 30px;
            @media (max-width: 767px) {
              padding-left: 16px;
            }
          }
          &:last-child {
            padding-right: 30px;
            @media (max-width: 767px) {
              padding-right: 16px;
            }
          }
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 16px;
            padding: 12px 12px 12px;
          }
        }
        &:nth-child(odd) {
          td {
            background-color: #fff;
          }
        }
        &:first-child {
          td {
            &:first-child {
              border-top-left-radius: 10px;
            }
            &:last-child {
              border-top-right-radius: 10px;
            }
          }
        }
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 10px;
            }
            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }
  }
  .MuiPagination-ul {
    li {
      button {
        width: 42px;
        height: 42px;
        border-radius: 100px;
        font-size: 15px;
        padding-top: 4px;
        display: flex;
        align-items: center;
        background: #edf2f7 !important;
        opacity: 1 !important;
        @media (max-width: 767px) {
          width: 28px;
          height: 28px;
          min-width: 28px;
          font-size: 15px;
        }
        svg {
          position: relative;
          top: -2px;
        }
        &.Mui-selected {
          background: #000c3d !important;
          color: #fff !important;
        }
      }
    }
  }
}

.ambassador-info-popup {
  .MuiPaper-root {
    width: 100%;
    max-width: 480px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    .MuiDialogContent-root {
      padding: 30px 30px;
      box-sizing: border-box;
      width: 100% !important;
      .MuiTypography-root {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .modal-title {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin: 0 0 0 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      max-width: 340px;
    }
    &__button {
      margin-top: 10px;
    }
    &__back {
      margin-top: 20px;
      span {
        border-bottom: 1px solid #607790;
        cursor: pointer;
        color: #607790;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
      }
    }
  }
}

.ambassador-tutorial-body {
  color: var(--primary-dark-color);
  font-family: "TTCommons-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0 0 0 0;
  text-align: left;
}

.react-joyride__tooltip {
  border-radius: 7px !important;
  padding: 30px 27px 19px 27px !important;
  > div:first-child {
    > div {
      padding: 0 0 0 0 !important;
    }
  }
  > div:nth-child(2) {
    margin-top: 10px !important;
    > div {
      > button {
        color: var(--primary-dark-color) !important;
        font-family: "TTCommons-Demibold" !important;
        font-size: 14px !important;
        letter-spacing: 2px !important;
        line-height: 18px !important;
        font-weight: 600 !important;
        padding: 0 0 0 0 !important;
      }
    }
    > button {
      height: 40px !important;
      width: 96px !important;
      border-radius: 45px !important;
      background-color: var(--secondary-color) !important;
    }
  }
}

.ambassador-tag-community {
  // Mask start
  background: white !important;
  width: fit-content;
  height: fit-content;
  border-radius: 12.5px;
  // Mask end
  &__component {
    background: rgba(247, 100, 95, 0.09);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 86px;
    border-radius: 12.5px;
    cursor: pointer;
    margin-top: 5px;
    span {
      height: 11px;
      width: 74px;
      color: #a80c06;
      font-family: "TTCommons-Medium";
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }
  }
}

.ambassadorTagTopicMiddle {
  background: rgba(247, 100, 95, 0.09);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 90px;
  border-radius: 12.5px;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 4px;
  span {
    height: 11px;
    width: 74px;
    color: #a80c06;
    font-family: "TTCommons-Medium";
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
  }
}

/** Smartbanner - START **/
.smartbanner-show .smartbanner {
  display: block;
}

.smartbanner-android,
.smartbanner-ios {
  background: #fff;
  box-shadow: var(--card-box-shadow);

  .smartbanner-close {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 5px;
    font-family: "ArialRoundedMTBold", Arial;
    font-size: 20px;
    text-align: center;
    color: #fff;
    background: var(--primary-dark-color);
    text-decoration: none;
    border: 0;
    border-radius: 14px;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .smartbanner-close:active,
  .smartbanner-close:hover {
    color: var(--primary-color);
  }

  .smartbanner-icon {
    display: inline-block;
    vertical-align: middle;
    width: 57px;
    height: 57px;
    margin-right: 12px;
    background-size: cover;
    border-radius: 14px;
  }

  .smartbanner-title {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
  }

  // Hide price tag
  .smartbanner-description {
    display: none;
  }

  .smartbanner-info {
    display: inline-block;
    vertical-align: middle;
    width: 44%;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 13px;
  }

  .smartbanner-button {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background-color: var(--secondary-color);
    color: $color-white;
    cursor: pointer;
    border-radius: 20px;
    min-width: 60px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
    overflow: hidden;
    .smartbanner-button-text {
      color: #fff;
      background: var(--secondary-color);
      font-family: "TTCommons-Medium";
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      border: none;
    }
  }
}
/** Smartbanner - End **/
.label-hidden {
  color: transparent !important; /* make the text invisible */
  user-select: none !important; /* prevent selection of the text */
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}

.contact-career-model {
  &__body {
    p {
      margin: 0 0 0 0;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
    }
    &__mb-1 {
      margin-bottom: 1rem !important;
    }
    &__mt-1 {
      margin-top: 1rem !important;
    }
    &__upload {
      margin-bottom: 1.5rem;
    }
    &__submit {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: center;
    }
    &__cancel {
      display: flex;
      justify-content: center;
      p {
        width: fit-content;
        color: var(--gray-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        cursor: pointer;
        border-bottom: 2px solid var(--gray-color);
      }
    }
  }
}

.interstride-products-banner {
  .title {
    font-family: "TTCommons-Regular";
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--primary-dark-color);
  }
  .subtitle {
    font-family: "TTCommons-Regular";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-dark-color);
    margin: 10px;
    text-align: center;
    width: auto;
  }
  .action-images {
    display: flex;
    justify-content: center;
    img {
      margin: auto 3px;
      cursor: pointer;
    }
  }
}

.edit-topic {
  .MuiDialogContent-root {
    padding: 12px 0 0 0 !important;
  }
  .MuiDialog-paper {
    overflow-y: visible;
    .MuiDialogContent-root {
      overflow-y: visible;
    }
  }
  &__body {
    .card-wrapper {
      .create-post {
        box-shadow: none !important;
        .preview-nbox-wrapper .img-wrap {
          height: 200px !important;
        }
      }
    }
  }
}

.suggest-topic-modal {
  .MuiPaper-root {
    width: 100%;
    margin: 0;
    max-width: 480px;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4);
  }
  .MuiDialogContent-root {
    width: 100% !important;
    padding: 30px;
    .MuiDialogContentText-root {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  &__body {
    margin-top: 20px;
    &__text {
      margin-bottom: 15px;
    }
    &__button {
      display: flex;
      justify-content: center;
    }
  }
}

.delete-post {
  .MuiDialogContent-root {
    padding: 12px 0 0 0 !important;
    max-width: 480px;
  }
  &__body {
    margin-top: 20px;
    &__button {
      display: flex;
      justify-content: center;
    }
  }
}

.comment-box {
  margin-top: 15px;
  &__text {
    width: 100%;
    > div {
      margin-bottom: 0;
    }
    .form-label {
      margin: 0 0 10px 4px !important;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      padding: 5px 0 0 0 !important;
    }
    .MuiInputBase-multiline {
      padding: 0;
      margin-bottom: 0;
      textarea {
        border: 2px solid var(--gray-outline-color);
        border-radius: 10px;
        background-color: #ffffff;
        padding: 21px 20px;
        box-sizing: border-box;
        font-size: 18px;
        line-height: 22px;
        font-family: "TTCommons-Regular", "Apple Color Emoji",
          "NotoColorEmoji-Regular";
        color: var(--primary-dark-color);
        max-height: 100px;
        overflow: auto !important;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--field-label-color) !important;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--field-label-color) !important;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--field-label-color) !important;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--field-label-color) !important;
        }
        // --Focus style
        &:focus {
          border-color: var(--gray-color) !important;
        }
        &:hover {
          border-color: #bfced8;
        }
        &:focus {
          border-color: var(--gray-color);
        }
        /* scroll styling start */
        scrollbar-width: thin !important;
        scrollbar-color: var(--light-gray-color) transparent; /* scroll thumb and track */
        &::-webkit-scrollbar {
          width: 7px; /* width of the entire scrollbar */
          transition: all ease-in-out 500ms;
        }
        &::-webkit-scrollbar-track {
          background: transparent !important; /* color of the tracking area */
          border-radius: 50px;
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
            29px 0px;
          margin: 10px 0 10px 0;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(
            --light-gray-color
          ) !important; /* color of the scroll thumb */
          border-radius: 50px; /* roundness of the scroll thumb */
          height: 40px;
        }
        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }
        &::-webkit-scrollbar-thumb:hover {
          border: 0;
          background-color: var(
            --gray-color
          ) !important; /* color of the scroll thumb */
        }
        /* scroll styling end */
      }
    }
  }
  &__action {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
    &__left {
      display: flex;
      gap: 5px;
      .emoji-picker-wrapper {
        height: 35px;
        .emoji-icon {
          cursor: pointer;
        }
        .emoji-picker {
          position: absolute;
          width: 100%;
          left: 0px;
          top: 40px;
          z-index: 10;
        }
      }
    }
    &__post {
      button {
        height: 35px;
        width: 78px;
        border-radius: 45px;
        .content {
          .button-spin {
            margin: 0 0 0 0 !important;
          }
        }
      }
    }
  }
}

.job-type-wrapper {
  margin-bottom: 10px;
  &__title {
    font-family: "TTCommons-Regular";
    font-size: 15px;
    line-height: 17px;
    color: var(--f-light-gray-color);
    margin-left: 8px;
  }
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 5px;
    margin-left: 6px;
  }
}

.job-filter-accordian {
  margin-bottom: 10px;
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordionSummary-root {
    padding: 0 0 0 0;
    min-height: initial;
  }
  .MuiAccordionSummary-content {
    align-items: center;
    gap: 5px;
    margin: 6px 0 0 0;
    .job-filter-accordian__title {
      font-family: "TTCommons-Regular";
      font-size: 15px;
      line-height: 17px;
      color: var(--f-light-gray-color);
      margin-left: 8px;
      a {
        height: 19px;
      }
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 6px 0 0 0;
  }
  .job-filter-accordian__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 5px;
  }
  .job-filter-accordian__dropdown {
    width: 100%;
    margin-top: 8px;
    > div {
      > div {
        margin-bottom: 0px !important;
      }
    }
  }
  .MuiAccordionDetails-root {
    padding: 0 0 0 6px;
  }
  .MuiIconButton-root {
    padding: 0px 12px;
  }
  .job-filter-accordian__radio-group {
    margin-top: 8px;
    &__item {
      display: flex;
      margin-bottom: 6px;
      &__label {
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 19px;
        color: var(--gray-color);
        margin: 6px 0 0 5px;
        &--checked {
          color: var(--primary-dark-color) !important;
        }
      }
    }
  }
}

.a-checkbox-job {
  display: flex;
  align-items: center;
  &__input {
    .MuiIconButton-root {
      padding: initial !important;
    }
  }
  &__description {
    &__label {
      margin: 5px 0 0 8px;
      font-family: "TTCommons-Regular";
      font-weight: 400;
      font-size: 18px;
      line-height: 19px;
      color: var(--gray-color);
      &--checked {
        color: var(--primary-dark-color);
      }
      &__count {
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: var(--gray-color) !important;
        margin: 0 0 0 5px;
        min-width: fit-content;
      }
    }
  }
}

.ambassador-profile-tutorial-two {
  @media (max-width: 780px) {
    width: 100%;
  }
}

// Hide google translate top bar
.skiptranslate {
  display: none !important;
}

.info-icon-tooltip {
  h2 {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin: 0 auto 4px;
  }
  h3 {
    font-family: "TTCommons-Medium";
    font-weight: 500;
    margin: 0 0 5px 0;
    font-size: 18px;
    line-height: 19px;
  }
  p {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
    b {
      font-family: "TTCommons-DemiBold";
      font-weight: 600;
    }
    p {
      margin: 5px 0;
    }
  }
  .close-button {
    margin-top: 25px;
    text-align: center;
    button {
      height: 30px;
      width: 90px;
    }
  }
}

.multi-select {
  .multi-select-option {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px;
    flex-shrink: 0;
    flex-wrap: wrap;

    > div {
      align-items: center;
    }

    .MuiCheckbox-root {
      border-radius: 3px;
      width: 17px;
      height: 17px;
      margin-right: 9px;
    }
    .multi-select-label {
      color: $color-grey-2;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      margin: 4px 0px 0px 0px;
    }
    .multi-select-label-selected {
      color: var(--primary-dark-color);
    }
    .multi-select-label-focused {
      color: #fff !important;
    }
  }
  .multi-select-count {
    font-family: "TTCommons-Regular";
    font-size: 15px;
    line-height: 17px;
    color: var(--f-light-gray-color);
    margin: 6px 5px 3px 5px;
  }
}

.checkbox-filter-title {
  font-family: "TTCommons-Regular";
  font-size: 15px;
  line-height: 17px;
  color: var(--f-light-gray-color);
  margin-left: 8px;
}
.checkbox-filter-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
}

.checkbox-filter {
  align-items: center;
  gap: 5px;
  margin: 6px 0 0 0;

  .checkbox-filter-list {
    margin: 5px 0px 15px 5px;
  }
}

.label-student-view-dropdown {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0 6px 4px !important;
  &__label {
    color: var(--field-label-color);
    font-size: 15px;
  }
  &__tooltip {
    display: inline-block;
    margin-top: -4px;
  }
}

.field-of-study-tag-beta {
  border-radius: 100px;
  background-color: #f1f4f7;
  color: var(--primary-dark-color);
  font-family: "TTCommons-Regular";
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 3px 10px 0;
  display: inline-block;
  white-space: nowrap !important;
  margin: -3px 0 0 10px;
}

.invite-friend {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__form-field-group {
    width: 360px;
    margin-bottom: 20px;
    input {
      text-align: center !important;
    }
  }
}
